<template>
  <button class="charla__button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "HeaderButton"
}
</script>

<style scoped lang="scss">
  @import "@/scss/mixins";

  .charla__button{
    padding: 9px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    border-radius: 20px;
    background-color: white;
    width: 100%;
    transition: 300ms ease all;

    &:hover{
      background-color: black;
      color: white;
    }
  }

</style>