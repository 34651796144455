import { createStore } from 'vuex'
import pagination from './modules/pagination'
import popupStates from "./modules/popupStates";

const store = createStore({
    modules: {
        pagination,
        popupStates
    }
})

export default store