<template>
  <CharlaFormPopup></CharlaFormPopup>
  <div class="subPage-background">
    <div class="subPage-wrapper">
      <subPageHeader></subPageHeader>
      <div class="reviews-wrapper">
        <div class="page-title">
          <h1>{{ $t("reviewsPage.title") }} <span>4</span></h1>
          <div class="title-btn" @click="setFormState(true)">
            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M14 0H8C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16H14C18.42 16 22 12.42 22 8C22 3.58 18.42 0 14 0ZM14 14H8C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2H14C17.31 2 20 4.69 20 8C20 11.31 17.31 14 14 14ZM7 5H15V7H7V5ZM7 9H11V11H7V9Z"
                  fill="#98DA2C"/>
            </svg>
            {{ $t("reviewsPage.btn") }}
          </div>
        </div>
        <div class="reviews-grid">
          <div class="review">
            <svg class="review-clip" width="32" height="32" viewBox="0 0 32 32" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white"/>
            </svg>
            <div class="review-header">
              <div class="name" v-html='$t("reviewsPage.reviews.review1.name")'></div>
              <div class="date">{{$t("reviewsPage.reviews.review1.date")}}</div>
            </div>
            <div class="review-text">
              {{$t("reviewsPage.reviews.review1.text")}}
            </div>
          </div>
          <div class="review">
            <svg class="review-clip" width="32" height="32" viewBox="0 0 32 32" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white"/>
            </svg>
            <div class="review-header">
              <div class="name" v-html='$t("reviewsPage.reviews.review2.name")'></div>
              <div class="date">{{$t("reviewsPage.reviews.review2.date")}}</div>
            </div>
            <div class="review-text">
              {{$t("reviewsPage.reviews.review2.text")}}
            </div>
          </div>
          <div class="review">
            <svg class="review-clip" width="32" height="32" viewBox="0 0 32 32" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white"/>
            </svg>
            <div class="review-header">
              <div class="name" v-html='$t("reviewsPage.reviews.review3.name")'></div>
              <div class="date">{{$t("reviewsPage.reviews.review3.date")}}</div>
            </div>
            <div class="review-text">
              {{$t("reviewsPage.reviews.review3.text")}}
            </div>
          </div>
          <div class="review">
            <svg class="review-clip" width="32" height="32" viewBox="0 0 32 32" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M32 2.54313e-06L0 0C17.6731 7.72516e-07 32 14.3271 32 32L32 2.54313e-06Z" fill="white"/>
            </svg>
            <div class="review-header">
              <div class="name" v-html='$t("reviewsPage.reviews.review4.name")'></div>
              <div class="date">{{$t("reviewsPage.reviews.review4.date")}}</div>
            </div>
            <div class="review-text">
              {{$t("reviewsPage.reviews.review4.text")}}
            </div>
          </div>
        </div>
      </div>
      <subPageFooter></subPageFooter>
    </div>
  </div>
</template>

<script>
import subPageHeader from "@/components/subPages/subPageHeader.vue";
import subPageFooter from "@/components/subPages/subPageFooter.vue";
import {mapActions} from "vuex";
import CharlaFormPopup from "@/components/CharlaFormPopup.vue";

export default {
  name: "reviewsPage",
  components: {subPageHeader, subPageFooter, CharlaFormPopup},

  mounted() {
    window.addEventListener('popstate', this.handleBackButton);
  },


  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  },

  methods: {
    ...mapActions(['setFormState']),

    handleBackButton(event) {
      event.preventDefault();
      window.location.href = '/';
    }
  }
}
</script>

<style scoped lang="sass">
.reviews-grid
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 16px

.review
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-between
  background: #fff
  max-width: 360px
  width: 100%
  height: 360px
  box-sizing: border-box
  border-radius: 0 32px 32px 32px
  box-shadow: inset 8px 0 0 0 #98DA2C
  padding: 32px

  .review-clip
    position: absolute
    top: 0
    left: -24px
    filter: drop-shadow(-8px 0 0 #98DA2C)

.review-header
  display: flex
  justify-content: space-between
  align-items: center

  .date
    font-size: 12px
    line-height: 150%

  .name
    font-weight: 600
    font-size: 22px
    line-height: 127%

.review-text
  font-size: 14px
  line-height: 157%


.subPage-background
  background: #F6F5F8

.subPage-wrapper
  max-width: 1112px
  margin: auto
  padding: 64px 0

.faq-wrapper
  margin: 64px auto

.page-title
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 64px

h1
  font-size: 56px
  font-weight: 700
  margin: 0

  span
    color: #98DA2C

.title-btn
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  max-width: 240px
  width: 100%
  height: 48px
  border-radius: 24px
  background: #000
  color: #fff
  transition: .3s ease
  cursor: pointer
  border: 1px solid #000

  &:hover
    background: #fff
    color: #000

    svg path
      transition: .3s ease
      fill: #000

p
  font-size: 16px
  margin-bottom: 12px
  line-height: 164%

  &:last-child
    margin-bottom: 0

ul
  padding-left: 30px

  li
    font-size: 16px
    margin-bottom: 12px
    line-height: 164%
    list-style-type: disc

h2
  font-size: 32px
  margin: 40px 0 30px 0

h3
  font-size: 24px
  font-weight: 400
  margin: 0

@media screen and (max-width: 1200px)
  .subPage-wrapper
    margin: 0 40px

@media screen and (max-width: 970px)
  .reviews-grid
    grid-template-columns: 1fr 1fr
  h1
    font-size: 42px

@media screen and (max-width: 660px)
  .page-title
    display: flex
    flex-direction: column
    gap: 22px
  .reviews-grid
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  .review
    max-width: 300px
    height: 280px
  .review-header
    .name
      font-size: 18px
  .review-text
    font-size: 13px
  .subPage-wrapper
    margin: 0 20px
    padding: 24px 0
  .faq-wrapper
    width: 100%
    margin: 24px 0
  h1
    font-size: 42px
    font-weight: 400
  h2
    font-size: 24px
  h3
    font-size: 18px

  p
    font-size: 13px
    line-height: 154%
  ul li
    font-size: 13px
    line-height: 154%
  .title-btn
    font-size: 14px
    max-width: 200px


</style>