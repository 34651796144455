<template>
  <header class="header">
    <div class="header__burger" @click="this.getNewMenuState('opened')">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="black"/>
        <path d="M10 19H12V21H10V19Z" fill="white"/>
        <path d="M16 18H18V22H16V18Z" fill="white"/>
        <path d="M22 16H24V24H22V16Z" fill="white"/>
        <path d="M28 19H30V21H28V19Z" fill="white"/>
      </svg>
    </div>
    <div class="header__logo">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.3279 15.5408L35.1106 10.5296L35.171 10.669L23.3883 15.6803L23.3279 15.5408ZM22.6371 14.5268L32.6496 6.6507L32.4509 6.40986L22.4385 14.2859L22.6371 14.5268ZM21.6052 13.5887L29.066 3.35493L28.6774 3.08451L21.2166 13.3183L21.6052 13.5887ZM24.5541 1.05211L23.9539 0.84507L19.6839 12.7014L20.284 12.9085L24.5541 1.05211ZM19.6536 0.0464789L18.8635 0L18.0864 12.5493L18.8765 12.5958L19.6536 0.0464789ZM17.4733 12.6507L14.7964 0.350704L13.8638 0.54507L16.5363 12.8408L17.4689 12.6465L17.4733 12.6507ZM16.1262 13.0479L10.2456 1.86761L9.2569 2.3662L15.1374 13.5465L16.1262 13.0479ZM14.9216 13.7577L6.22164 4.50423L5.28472 5.34507L13.9846 14.5944L14.9216 13.7535V13.7577ZM13.9242 14.7592L2.97481 8.18028L2.22787 9.37183L13.1773 15.9465L13.9242 14.7549V14.7592ZM36 23.4465L24.2173 18.431L23.1379 20.8606L22.1708 22.0056L20.9403 22.8423L19.5284 23.3324L18.0389 23.4423L16.5277 23.1423L15.1331 22.4324L14.0365 21.4183L13.2636 20.1592L12.8664 18.7901H12.8578L12.8491 17.4085H12.8146L13.1988 16.0479L0.811705 12.7056L0.388582 14.2056L12.2706 17.4127L0 17.5014L0.0129527 19.2127L11.6445 19.1282L0.509475 22.2169L1.02758 24.0127L11.6402 21.0718L2.23651 26.607L3.30295 28.3394L12.2749 23.0535L5.1077 30.4521L6.72248 31.9521L13.6867 24.7606L9.0367 33.4944L11.1523 34.5718L15.6685 26.0958L13.7429 35.4127L16.2384 35.907L17.987 27.4521L18.6433 36L21.3418 35.8014L20.7373 27.9254L23.4272 35.3155L26.1257 34.3732L23.6215 27.4944L27.8527 33.4437L30.3353 31.7535L26.3286 26.1211L31.6954 30.4563L33.7203 28.0521L28.4399 23.7887L34.6615 26.438L35.9957 23.438L36 23.4465Z" fill="black" />
      </svg>
      <p class="header__title">Charla</p>
    </div>
    <nav class="header__nav">
      <div class="header__nav__group">
        <HeaderBorderButton data-slide-number="4">{{ $t("header.button1") }}</HeaderBorderButton>
        <HeaderBorderButton data-slide-number="5">{{ $t("header.button2") }}</HeaderBorderButton>
        <HeaderBorderButton data-slide-number="6">{{ $t("header.button3") }}</HeaderBorderButton>
      </div>
      <div class="header__nav__group">
        <HeaderButton data-slide-number="7">{{ $t("header.button4") }}</HeaderButton>
        <HeaderButton data-slide-number="8">{{ $t("header.button5") }}</HeaderButton>
        <HeaderButton data-slide-number="9">{{ $t("header.button6") }}</HeaderButton>
      </div>
    </nav>
  </header>
</template>

<script>
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions(['getNewMenuState'])
  }
}


</script>
<style scoped lang="sass">
.ru-locale
  .charla__border__button, .charla__button
    font-size: 12px
    padding: 9px
</style>
<style scoped lang="scss">
  @import "@/scss/mixins";

  .header__title{
    font-size: 22px;
    font-weight: 600;
  }
  .header{
    display: grid;
    grid-template-columns: auto 1fr;
    position: fixed;
    left: 0;
    top: 24px;
    padding: 24px 0 24px 200px;
    z-index: 2;
    width: calc(100vw - 424px + 48px);
    transform: translate3d(0, 0, 0);

    &__burger{
      display: none;
      place-items: center;
      background-color: black;
      border-radius: 50%;
    }

    &__title{
      user-select: none;
    }

    &__logo{
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 12px;
      padding: 0 adapt(60px, 20px, 40) 0 0;
    }

    &__nav{
      display: grid;
      grid-template: auto / 1fr 1fr;
      width: 100%;

      &__group{
        display: grid;
        grid-template: auto / 1fr 1fr 1fr;
        gap: 16px;
        padding: 0 24px;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .header{
      padding: 24px 0 24px 168px;
      width: calc(100vw - 344px);
    }
  }

  @media screen and (max-width: 1440px){
    .header{
      padding: 24px 0 24px 56px;
      width: calc(100vw - 240px);
      grid-template-columns: auto auto 1fr;
    }

    .header__logo{
      padding: 0 0 0 0;
    }

    .header__burger{
      display: grid;
      margin: 0 24px 0 0;

    }
    .header__burger svg rect{
      transition: .3s ease;
    }
    .header__burger:hover svg rect{
      fill: #B561F6;
    }
  }
  @media screen and (max-width: 1100px){
    .header {
      padding: 24px 0 24px 24px ;
    }
    .charla__border__button, .charla__button {
      display: none;
    }
  }
</style>