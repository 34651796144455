<template>
  <div class="subPage-header">
    <div class="header">
      <a href="/" class="logo">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.3279 15.5408L35.1106 10.5296L35.171 10.669L23.3883 15.6803L23.3279 15.5408ZM22.6371 14.5268L32.6496 6.6507L32.4509 6.40986L22.4385 14.2859L22.6371 14.5268ZM21.6052 13.5887L29.066 3.35493L28.6774 3.08451L21.2166 13.3183L21.6052 13.5887ZM24.5541 1.05211L23.9539 0.84507L19.6839 12.7014L20.284 12.9085L24.5541 1.05211ZM19.6536 0.0464789L18.8635 0L18.0864 12.5493L18.8765 12.5958L19.6536 0.0464789ZM17.4733 12.6507L14.7964 0.350704L13.8638 0.54507L16.5363 12.8408L17.4689 12.6465L17.4733 12.6507ZM16.1262 13.0479L10.2456 1.86761L9.2569 2.3662L15.1374 13.5465L16.1262 13.0479ZM14.9216 13.7577L6.22164 4.50423L5.28472 5.34507L13.9846 14.5944L14.9216 13.7535V13.7577ZM13.9242 14.7592L2.97481 8.18028L2.22787 9.37183L13.1773 15.9465L13.9242 14.7549V14.7592ZM36 23.4465L24.2173 18.431L23.1379 20.8606L22.1708 22.0056L20.9403 22.8423L19.5284 23.3324L18.0389 23.4423L16.5277 23.1423L15.1331 22.4324L14.0365 21.4183L13.2636 20.1592L12.8664 18.7901H12.8578L12.8491 17.4085H12.8146L13.1988 16.0479L0.811705 12.7056L0.388582 14.2056L12.2706 17.4127L0 17.5014L0.0129527 19.2127L11.6445 19.1282L0.509475 22.2169L1.02758 24.0127L11.6402 21.0718L2.23651 26.607L3.30295 28.3394L12.2749 23.0535L5.1077 30.4521L6.72248 31.9521L13.6867 24.7606L9.0367 33.4944L11.1523 34.5718L15.6685 26.0958L13.7429 35.4127L16.2384 35.907L17.987 27.4521L18.6433 36L21.3418 35.8014L20.7373 27.9254L23.4272 35.3155L26.1257 34.3732L23.6215 27.4944L27.8527 33.4437L30.3353 31.7535L26.3286 26.1211L31.6954 30.4563L33.7203 28.0521L28.4399 23.7887L34.6615 26.438L35.9957 23.438L36 23.4465Z" fill="black" />
        </svg>
        <p class="header__title">Charla</p>
      </a>
      <a href="/" class="back-btn">
        <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 6.58V9.42L6.58 16L8 14.58L2.42 9H48V7L2.42 7L8 1.42L6.58 0L0 6.58Z" fill="black" />
        </svg>
        {{ $t("subPagesUI.back_btn") }}</a>
    </div>
  </div>
</template>

<script>
export default ({
  name: "subPageHeader",
  methods:{
  }
})
</script>

<style scoped lang="sass">
a
  text-decoration: none
.header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 64px
.logo
  display: flex
  align-items: center
  gap: 12px
  font-weight: 600
  font-size: 22px
  color: #000
.back-btn
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  max-width: 240px
  width: 100%
  height: 40px
  font-size: 14px
  color: #000
  border: 1px solid #000
  border-radius: 20px
  transition: .3s ease
  &:hover
    background: #000
    color: #fff
    svg path
      transition: .3s ease
      fill: #fff
@media screen and (max-width: 660px)
  .back-btn
    max-width: 185px
    width: 100%
    font-size: 12px
    gap: 4px
    svg
      width: 18px

</style>