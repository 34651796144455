<template>
  <div class="block" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <div class="block__title">
        <span>
          <span class="block__title__icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 24"
              style="enable-background:new 0 0 40 24;" xml:space="preserve">
              <path class="st0" d="M5,9h1v1H5c-0.3,0-0.7,0-1-0.1l0.2-1C4.5,9,4.7,9,5,9z M13,9h-1v1h1c0.3,0,0.7,0,1-0.1l-0.2-1
                    C13.5,9,13.3,9,13,9z M17.2,7.8l-0.8-0.6C16,7.7,15.7,8,15.2,8.3l0.6,0.8C16.3,8.8,16.8,8.3,17.2,7.8z M16.9,4.2C17,4.5,17,4.7,17,5
                    s0,0.5-0.1,0.8l1,0.2C18,5.7,18,5.3,18,5s0-0.7-0.1-1L16.9,4.2z M16.3,2.8l0.8-0.6c-0.4-0.5-0.8-1-1.4-1.4l-0.6,0.8
                    C15.7,2,16,2.3,16.3,2.8z M4,0.1l0.2,1C4.5,1,4.7,1,5,1h1V0H5C4.7,0,4.3,0,4,0.1z M0.8,2.2l0.8,0.6C2,2.3,2.3,2,2.8,1.7L2.2,0.8
                    C1.7,1.2,1.2,1.7,0.8,2.2z M1.1,5.8C1,5.5,1,5.3,1,5s0-0.5,0.1-0.8L0.1,4C0,4.3,0,4.7,0,5s0,0.7,0.1,1L1.1,5.8z M1.7,7.2L0.8,7.8
                    c0.4,0.5,0.8,1,1.4,1.4l0.6-0.8C2.3,8,2,7.7,1.7,7.2z M8,1h2V0H8V1z M12,0v1h1c0.3,0,0.5,0,0.8,0.1l0.2-1C13.7,0,13.3,0,13,0H12z
                     M8,10h2V9H8V10z M4.2,22.9l-0.2,1C4.3,24,4.7,24,5,24h1v-1H5C4.7,23,4.5,23,4.2,22.9z M13,23h-1v1h1c0.3,0,0.7,0,1-0.1l-0.2-1
                    C13.5,23,13.3,23,13,23z M15.2,22.3l0.6,0.8c0.5-0.4,1-0.8,1.4-1.4l-0.8-0.6C16,21.7,15.7,22,15.2,22.3z M16.9,18.2
                    c0,0.3,0.1,0.5,0.1,0.8s0,0.5-0.1,0.8l1,0.2c0.1-0.3,0.1-0.6,0.1-1s0-0.7-0.1-1L16.9,18.2z M16.3,16.8l0.8-0.6
                    c-0.4-0.5-0.8-1-1.4-1.4l-0.6,0.8C15.7,16,16,16.3,16.3,16.8z M4,14.1l0.2,1C4.5,15,4.7,15,5,15h1v-1H5C4.7,14,4.3,14,4,14.1z
                     M0.8,16.2l0.8,0.6C2,16.3,2.3,16,2.8,15.7l-0.6-0.8C1.7,15.2,1.2,15.7,0.8,16.2z M1,19c0-0.3,0-0.5,0.1-0.8l-1-0.2
                    C0,18.3,0,18.7,0,19s0,0.7,0.1,1l1-0.2C1,19.5,1,19.3,1,19z M1.7,21.2l-0.8,0.6c0.4,0.5,0.8,1,1.4,1.4l0.6-0.8
                    C2.3,22,2,21.7,1.7,21.2z M8,15h2v-1H8V15z M12,14v1h1c0.3,0,0.5,0,0.8,0.1l0.2-1C13.7,14,13.3,14,13,14H12z M8,24h2v-1H8V24z
                     M26.2,8.9l-0.2,1c0.3,0.1,0.6,0.1,1,0.1h1V9h-1C26.7,9,26.5,9,26.2,8.9z M35,9h-1v1h1c0.3,0,0.7,0,1-0.1l-0.2-1C35.5,9,35.3,9,35,9
                    z M39.2,7.8l-0.8-0.6C38,7.7,37.7,8,37.2,8.3l0.6,0.8C38.3,8.8,38.8,8.3,39.2,7.8z M39,5c0,0.3,0,0.5-0.1,0.8l1,0.2
                    C40,5.7,40,5.3,40,5s0-0.7-0.1-1l-1,0.2C39,4.5,39,4.7,39,5z M38.3,2.8l0.8-0.6c-0.4-0.5-0.8-1-1.4-1.4l-0.6,0.8
                    C37.7,2,38,2.3,38.3,2.8z M26,0.1l0.2,1C26.5,1,26.7,1,27,1h1V0h-1C26.7,0,26.3,0,26,0.1z M22.8,2.2l0.8,0.6C24,2.3,24.3,2,24.8,1.7
                    l-0.6-0.8C23.7,1.2,23.2,1.7,22.8,2.2z M23.1,5.8C23,5.5,23,5.3,23,5s0-0.5,0.1-0.8l-1-0.2C22,4.3,22,4.7,22,5s0,0.7,0.1,1L23.1,5.8
                    z M23.7,7.2l-0.8,0.6c0.4,0.5,0.8,1,1.4,1.4l0.6-0.8C24.3,8,24,7.7,23.7,7.2z M30,1h2V0h-2V1z M34,0v1h1c0.3,0,0.5,0,0.8,0.1l0.2-1
                    C35.7,0,35.3,0,35,0H34z M30,10h2V9h-2V10z M26.2,22.9l-0.2,1c0.3,0.1,0.6,0.1,1,0.1h1v-1h-1C26.7,23,26.5,23,26.2,22.9z M35,23h-1
                    v1h1c0.3,0,0.7,0,1-0.1l-0.2-1C35.5,23,35.3,23,35,23z M37.2,22.3l0.6,0.8c0.5-0.4,1-0.8,1.4-1.4l-0.8-0.6
                    C38,21.7,37.7,22,37.2,22.3z M38.9,18.2c0,0.3,0.1,0.5,0.1,0.8s0,0.5-0.1,0.8l1,0.2c0.1-0.3,0.1-0.6,0.1-1s0-0.7-0.1-1L38.9,18.2z
                     M38.3,16.8l0.8-0.6c-0.4-0.5-0.8-1-1.4-1.4l-0.6,0.8C37.7,16,38,16.3,38.3,16.8z M26,14.1l0.2,1c0.3,0,0.5-0.1,0.8-0.1h1v-1h-1
                    C26.7,14,26.3,14,26,14.1z M22.8,16.2l0.8,0.6c0.3-0.4,0.7-0.8,1.1-1.1l-0.6-0.8C23.7,15.2,23.2,15.7,22.8,16.2z M23,19
                    c0-0.3,0-0.5,0.1-0.8l-1-0.2C22,18.3,22,18.7,22,19s0,0.7,0.1,1l1-0.2C23,19.5,23,19.3,23,19z M23.7,21.2l-0.8,0.6
                    c0.4,0.5,0.8,1,1.4,1.4l0.6-0.8C24.3,22,24,21.7,23.7,21.2z M30,15h2v-1h-2V15z M34,14v1h1c0.3,0,0.5,0,0.8,0.1l0.2-1
                    C35.7,14,35.3,14,35,14H34z M30,24h2v-1h-2V24z" fill="#FFFFFF" />
            </svg>
          </span><b>{{ $t("CharlaApplications.title_part1") }}</b></span>
        <span>{{ $t("CharlaApplications.title_part2") }}</span>
        <span>{{ $t("CharlaApplications.title_part3") }}</span>
      </div>
      <div class="applications__slogan" v-html='$t("CharlaApplications.slogan")'>
      </div>
      <div class="applications__link ">
        {{ $t("CharlaApplications.info") }}
      </div>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="app__carousel" data-carousel="true" data-hidden-index="0">
        <div class="app__carousel__wrapper">
          <div class="app__carousel__item">
            <div class="app__carousel__item__container app__carousel__item_purple">
              <div class="app__carousel__item__letter">A</div>
              <div class="app__carousel__item__title">
                Charla<br>
                <span>{{ $t("CharlaApplications.slider.carouselItem1.title") }}</span>
              </div>
              <p class="app__carousel__item__text">
                {{ $t("CharlaApplications.slider.carouselItem1.text_part1") }}
              </p>
              <div class="app__carousel__item__link" @click="this.setFormState(true)">
                {{ $t("CharlaApplications.slider.carouselItem1.btn") }}
              </div>
            </div>
          </div>
          <div class="app__carousel__item">
            <div class="app__carousel__item__container app__carousel__item_red">
              <div class="app__carousel__item__letter">B</div>
              <div class="app__carousel__item__title">
                {{ $t("CharlaApplications.slider.carouselItem2.title_part1") }}<br>
                <span>{{ $t("CharlaApplications.slider.carouselItem2.title_part2") }}</span>
              </div>
              <p class="app__carousel__item__text">
                {{ $t("CharlaApplications.slider.carouselItem2.text_part1") }}
                <span>{{ $t("CharlaApplications.slider.carouselItem2.text_part2") }}</span>
              </p>
              <a rel="nofollow noopener" :href="$t('charlaRegLink')" target="_blank" class="app__carousel__item__link">
                {{ $t("CharlaApplications.slider.carouselItem2.btn") }}</a>
            </div>
          </div>
          <div class="app__carousel__item">
            <div class="app__carousel__item__container app__carousel__item_blue">
              <div class="app__carousel__item__letter">C</div>
              <div class="app__carousel__item__title">
                Telegram<br>
                <span>{{ $t("CharlaApplications.slider.carouselItem3.title") }}</span>
              </div>
              <p class="app__carousel__item__text">
                {{ $t("CharlaApplications.slider.carouselItem3.text_part1") }}
                <span>{{ $t("CharlaApplications.slider.carouselItem3.text_part2") }}</span>
              </p>
              <a :href="$t('charlaTg')" target="_blank" class="app__carousel__item__link">
                {{ $t("CharlaApplications.slider.carouselItem3.btn") }}
              </a>
            </div>
          </div>
        </div>
        <div class="carousel__button__wrapper">
          <button :class="this.hiddenIndex > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'"
            class="carousel__button carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z"
                fill="white" />
            </svg>
          </button>
          <button :class="this.hideArrow ? 'carousel__button_disabled' : 'carousel__button_active'"
            class="carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z"
                fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Tr from "@/i18n/translation";

export default {
  name: "CharlaApplications",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false,
      currentLang: Tr.currentLocale
    }
  },
  computed: {
    Tr() {
      return Tr
    },
  },
  methods: {
    ...mapActions(['setFormState', 'setAppIndex']),
    scrollCarousel(direction = 'left') {
      const carouselItems = [...document.querySelectorAll('.app__carousel__item')]
      const carouselItemsContainer = [...document.querySelectorAll('.app__carousel__item__container')]
      const carouselButtons = [...document.querySelectorAll('.carousel__button')]

      carouselButtons.forEach(e => {
        e.style.pointerEvents = 'none'
        setTimeout(() => {
          e.style.pointerEvents = 'all'
        }, 400)
      })

      if (direction === 'right') {
        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex) {
            e.classList.add('disappear')
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex + 1)}% - ${16 * (this.hiddenIndex + 1)}px), 0)`
          }
        })

        this.hiddenIndex += 1
      } else {
        this.hiddenIndex -= 1

        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex) {
            e.classList.add('appear')
            setTimeout(() => {
              e.classList.remove('disappear')
              e.classList.remove('appear')
            }, 500)
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex)}% - ${16 * (this.hiddenIndex)}px), 0)`
          }
        })

      }

      const carousel = document.querySelector('.app__carousel');
      carousel.dataset.hiddenIndex = this.hiddenIndex
    }
  },
  mounted() {
    const splideContainer = document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
          this.hideArrow = true
        } else {
          splideContainer.dataset.stopScroll = 'true'
          this.hideArrow = false
        }
      })
    }, {
      threshold: 1
    });

    let money__statistic__carouselItem = [...document.querySelectorAll('.app__carousel__item')];
    money__statistic__carouselItem = money__statistic__carouselItem[money__statistic__carouselItem.length - 1]
    observer.observe(money__statistic__carouselItem)

    const carousel = document.querySelector('.app__carousel')
    this.hiddenIndex = +carousel.dataset.hiddenIndex


    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          this.hiddenIndex = +carousel.dataset.hiddenIndex
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  },
}
</script>

<style scoped lang="scss">
.block__title__icon {
  padding: 8px;
  background-color: var(--purple);

  &>svg {
    width: 100%;
    height: 100%;
  }
}

.applications__slogan {
  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
  color: #000;
  max-width: 368px;
}

.applications__link {
  font-weight: 600;
  font-size: 22px;
  line-height: 127%;
  color: #000;
}

.block__right {
  position: relative;
}

.app__carousel {
  position: absolute;
  left: -80px;
  top: 50%;
  transform: translate(0, -50%);

  &__wrapper {
    display: flex;
    gap: 16px;
    position: relative;
  }

  &__item {
    width: 360px;
    height: 360px;
    display: grid;
    place-items: center;

    &__container {
      border-radius: 32px;
      padding: 32px;
      display: grid;
      grid-template: auto 1fr auto / 1fr;
      row-gap: 16px;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__title {
      font-weight: 600;
      font-size: 40px;
      line-height: 120%;
      color: #000;
      position: relative;
      z-index: 1;

      &>span {
        color: white;
        position: relative;
        z-index: 0;
      }
    }

    &__letter {
      font-weight: 700;
      font-size: 120px;
      line-height: 100%;
      text-align: center;
      color: #fff;
      position: absolute;
      right: -10px;
      top: -34px;
    }

    &__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #fff;

      &>span {
        color: black;
      }
    }

    &__link {
      cursor: pointer;
      background-color: black;
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #fff;
      border-radius: 24px;
      width: fit-content;
      padding: 13px 24px;
    }

    &_purple {
      background-color: var(--purple);
    }

    &_red {
      background-color: var(--red);
    }

    &_green {
      background-color: var(--green);
    }

    &_blue {
      background-color: var(--blue);
    }
  }
}

.carousel__button {
  background-color: var(--purple);

  &__back {
    background-color: black;
  }
}
</style>