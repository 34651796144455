import { createI18n } from "vue-i18n";
import en from "@/i18n/locales/en.json";
import es from "@/i18n/locales/es.json";
// import ru from "@/i18n/locales/ru.json";
export default createI18n({
    locale: 'en',
    legacy: false,
    globalInjection: true,
    warnHtmlMessage: false,
    messages: {en,es}
})