<template>
  <div class="privacy__popup" v-show="getPrivacyState">
    <div class="privacy__background" @click="closePrivacy()"></div>
    <div class="privacy__close" @click="closePrivacy()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
            fill="black"/>
      </svg>
    </div>
    <div class="privacy__wrapper">
      <div class="privacy__container" id="ru_privacy">
        <div class="privacy__title">{{$t("privacyPolicy.mainTitle")}}</div>
        <div class="privacy__block">
          <p class="privacy__text" v-html="$t('privacyPolicy.start.text1')"></p>
          <p class="privacy__text" v-html="$t('privacyPolicy.start.text2')"></p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">1.</p>
            <div>{{$t("privacyPolicy.title1")}}</div>
          </div>
          <p class="privacy__text" v-html="$t('privacyPolicy.block1.text1')"></p>
          <p class="privacy__text" v-html="$t('privacyPolicy.block1.text2')"></p>
          <p class="privacy__text" v-html="$t('privacyPolicy.block1.text3')"></p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">2.</p>
            <div>{{$t("privacyPolicy.title2")}}</div>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">2.1</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text1")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.2</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text2")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.3</p>
              <p class="privacy__text" v-html='$t("privacyPolicy.block2.text3")'></p>
            </li>
            <li>
              <p class="privacy__list__number">2.4</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text4")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.5</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text5")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.6</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text6")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.7</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text7")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.8</p>
              <p class="privacy__text" v-html='$t("privacyPolicy.block2.text8")'></p>
            </li>
            <li>
              <p class="privacy__list__number">2.9</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text9")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.10</p>
              <p class="privacy__text" v-html='$t("privacyPolicy.block2.text10")'></p>
            </li>
            <li>
              <p class="privacy__list__number">2.11</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text11")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.12</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text12")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.13</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text13")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">2.14</p>
              <p class="privacy__text">{{$t("privacyPolicy.block2.text14")}}</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">3.</p>
            <div>{{$t("privacyPolicy.title3")}}</div>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">3.1</p>
              <p class="privacy__list__title">{{$t("privacyPolicy.title3_1")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block3_1.text1")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block3_1.text2")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">3.2</p>
              <p class="privacy__list__title">{{$t("privacyPolicy.title3_2")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block3_2.text1")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block3_2.text2")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block3_2.text3")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">3.3</p>
              <p class="privacy__list__title">{{ $t("privacyPolicy.title3_3") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block3_3.text1") }}</p>
            </li>
            <li>
              <p class="privacy__list__number">3.4</p>
              <p class="privacy__list__title">{{ $t("privacyPolicy.title3_4") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block3_4.text1") }}</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">4.</p>
            <div>{{$t("privacyPolicy.title4")}}</div>
          </div>
          <ol class="privacy__list">
            <p class="privacy__text">{{$t("privacyPolicy.title4_1")}}</p>
            <li>
              <p class="privacy__list__number">a)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block4_1.text1")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">b)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block4_1.text2")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">c)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block4_1.text3")}}</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">5.</p>
            <div>{{$t("privacyPolicy.title5")}}</div>
          </div>
          <p class="privacy__text">{{ $t("privacyPolicy.block5.text1") }}</p>
          <br>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">a)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block5.text2")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">b)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block5.text3")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">c)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block5.text4")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">d)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block5.text5")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">e)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block5.text6")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">f)</p>
              <p class="privacy__text">{{$t("privacyPolicy.block5.text7")}}</p>
            </li>
            <li>
              <p class="privacy__list__number">g)</p>
              <p class="privacy__text">{{ $t("privacyPolicy.block5.text8") }}</p>
            </li>
            <li>
              <p class="privacy__list__number">h)</p>
              <p class="privacy__text">{{ $t("privacyPolicy.block5.text9") }}</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">6.</p>
            <div>{{$t("privacyPolicy.title6")}}</div>
          </div>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text1") }}</p>
          <br>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text2") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text3") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text4") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text5") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text6") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text7") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text8") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text9") }}</p>
          <br>
          <p class="privacy__text">{{ $t("privacyPolicy.block6.text10") }}</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">7.</p>
            <div>{{$t("privacyPolicy.title7")}}</div>
          </div>
          <p class="privacy__text">{{$t("privacyPolicy.block7.text1")}}</p>
          <p class="privacy__text">{{$t("privacyPolicy.block7.text2")}}</p>
          <p class="privacy__text">{{$t("privacyPolicy.block7.text3")}}</p>
          <p class="privacy__text">{{$t("privacyPolicy.block7.text4")}}</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">8.</p>
            <div>{{$t("privacyPolicy.title8")}}</div>
          </div>

          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">8.1</p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text1")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text2")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text3")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text4")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text5")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block8.text6") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text7")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text8")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text9")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text" v-html="$t('privacyPolicy.block8.text10')"></p>
            </li>
            <li>
              <p class="privacy__list__number">8.2</p>
              <p class="privacy__text">{{$t("privacyPolicy.block8.text11")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block8.text12") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block8.text13") }}</p>
            </li>
          </ol>
          <br>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">9.</p>
            <div>{{$t("privacyPolicy.title9")}}</div>
          </div>
          <ol class="privacy__list">
            <li>
              <p class="privacy__list__number">9.1</p>
              <p class="privacy__text">{{$t("privacyPolicy.block9.text1")}}</p>
            </li>
            <li>
                <p class="privacy__list__number"></p>
                <p class="privacy__text">{{ $t("privacyPolicy.block9.text2") }}</p>
            </li>
            <li>
                <p class="privacy__list__number"></p>
                <p class="privacy__text">{{ $t("privacyPolicy.block9.text3") }}</p>
            </li>
            <li>
                <p class="privacy__list__number"></p>
                <p class="privacy__text">{{ $t("privacyPolicy.block9.text4") }}</p>
            </li>
            <li>
              <p class="privacy__list__number">9.2</p>
              <p class="privacy__text">{{$t("privacyPolicy.block9.text5")}}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block9.text6") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block9.text7") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block9.text8") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block9.text9") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block9.text10") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block9.text11") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block9.text12") }}</p>
            </li>
            <li>
              <p class="privacy__list__number"></p>
              <p class="privacy__text">{{ $t("privacyPolicy.block9.text13") }}</p>
            </li>
          </ol>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">10.</p>
            <div>{{$t("privacyPolicy.title10")}}</div>
          </div>
          <p class="privacy__text">{{$t("privacyPolicy.block10.text1")}}</p>
          <p class="privacy__text">{{$t("privacyPolicy.block10.text2")}}</p>
          <p class="privacy__text">{{$t("privacyPolicy.block10.text3")}}</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">11.</p>
            <div>{{$t("privacyPolicy.title11")}}</div>
          </div>
          <p class="privacy__text" v-html="$t('privacyPolicy.block11.text1')"></p>
          <p class="privacy__text">{{ $t("privacyPolicy.block11.text2") }}</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">12.</p>
            <div>{{$t("privacyPolicy.title12")}}</div>
          </div>
          <p class="privacy__text">{{ $t("privacyPolicy.block12.text1") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block12.text2") }}</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">13.</p>
            <div>{{$t("privacyPolicy.title13")}}</div>
          </div>
          <p class="privacy__text">{{ $t("privacyPolicy.block13.text1") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block13.text2") }}</p>
          <b><p class="privacy__text">{{ $t("privacyPolicy.block13.text3") }}</p></b>
          <br>
          <p class="privacy__text">{{ $t("privacyPolicy.block13.text4") }}</p>
          <p class="privacy__text" v-html='$t("privacyPolicy.block13.text5")'></p>
          <b><p class="privacy__text">{{ $t("privacyPolicy.block13.text6") }}</p></b>
          <br>
          <p class="privacy__text" v-html='$t("privacyPolicy.block13.text7")'></p>
          <b><p class="privacy__text">{{ $t("privacyPolicy.block13.text8") }}</p></b>
          <br>
          <p class="privacy__text" v-html='$t("privacyPolicy.block13.text9")'></p>
          <p class="privacy__text" v-html='$t("privacyPolicy.block13.text10")'></p>
          <p class="privacy__text" v-html='$t("privacyPolicy.block13.text11")'></p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">14.</p>
            <div>{{ $t("privacyPolicy.title14") }}</div>
          </div>
          <p class="privacy__text">{{ $t("privacyPolicy.block14.text1") }}</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">15.</p>
            <div>{{ $t("privacyPolicy.title15") }}</div>
          </div>
          <p class="privacy__text">{{ $t("privacyPolicy.block15.text1") }}</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">16.</p>
            <div>{{ $t("privacyPolicy.title16") }}</div>
          </div>
          <p class="privacy__text">{{ $t("privacyPolicy.block16.text1") }}</p>
          <p class="privacy__text">{{ $t("privacyPolicy.block16.text2") }}</p>
        </div>
        <div class="privacy__block">
          <div class="privacy__title_small">
            <p class="privacy__title__number">17.</p>
            <div>{{ $t("privacyPolicy.title17") }}</div>
          </div>
          <p class="privacy__text" v-html="$t('privacyPolicy.block17.text1')"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Tr from "@/i18n/translation";

export default {
  name: "CharlaPrivacyPopup",
  methods: {
    ...mapActions(['setPrivacyState', 'setFormState', 'setReadPrivacy']),
    closePrivacy() {
      const privacyWrapper = document.querySelector('.privacy__wrapper');
      const privacyBackground = document.querySelector('.privacy__background');
      const privacyClose = document.querySelector('.privacy__close')

      privacyWrapper.classList.add('privacy__wrapper__disable');
      privacyBackground.classList.add('privacy__background__disable');
      privacyClose.classList.add('privacy__close__disable')


      setTimeout(() => {
        privacyWrapper.classList.remove('privacy__wrapper__disable');
        privacyBackground.classList.remove('privacy__background__disable');
        privacyClose.classList.remove('privacy__close__disable')
        this.setPrivacyState(false)

        if (this.getReadPrivacy) {
          this.setReadPrivacy(false)
          this.setFormState(true)
        }
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['getPrivacyState', 'getReadPrivacy']),
    Tr(){
      return Tr
    }
  }
}
</script>

<style scoped lang="scss">
.main__grid {
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  border: 1px solid #98da2c;
  border-radius: 20px;
  overflow: hidden;
}

.main__text {
  border: 1px solid #98da2c;
  padding: 10px;
}

.privacy {
  &__background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__close {
    position: fixed;
    background-color: white;
    top: 48px;
    left: calc(50% + 418px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: 500ms ease forwards __slideUpClose;

    &__disable {
      animation: 500ms ease forwards __slideDownClose;
    }
  }

  &__wrapper {
    padding: 64px;
    position: fixed;
    left: 50%;
    top: 24px;
    transform: translate(-50%, 0);
    max-width: 836px;
    width: 100%;
    background-color: white;
    height: calc(100vh - 48px);
    overflow: hidden scroll;
    z-index: 10000;
    border-radius: 44px;
    animation: 500ms ease forwards __slideUp;

    &__disable {
      animation: 500ms ease forwards __slideDown;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;

    &_small {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 0 32px;
    }

    &__number {
      width: 48px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;

    b p{
      font-weight: 600;
    }

    & .privacy__text:last-of-type {
      padding: 0;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
    padding: 0 0 16px;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_padding {
      padding: 16px 0 0 0;
    }

    & > li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }

    & > .privacy__list_line {
      grid-template: 1fr / 96px 1fr;

      & > p {
        position: relative;

        & > svg {
          position: absolute;
          right: 30px;
          top: 10px;
        }
      }
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    &__number {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: var(--green);

      &_uncolored {
        padding: 32px 0 0 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        color: #000;
      }
    }
  }
}

.colored__text {
  color: #B561F6;
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }
  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }
  to {
    transform: translate(50%, 120vh);
  }
}

.short-line {
  grid-template: 1fr/46px 1fr !important;
}

@media screen and (max-width: 660px) {
  .privacy__wrapper {
    width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    padding: 0;
    top: 8px;
    border-radius: 26px;
    box-sizing: border-box;
  }
  .privacy__container {
    gap: 24px;
    padding: 16px;
  }
  .privacy__title {
    font-size: 22px;
    margin: 10px 0 0 0
  }
  .privacy__close {
    background: #000;
    top: 18px;
    right: 36px;
    left: unset;
    width: 36px;
    height: 36px;
  }
  .privacy__close svg {
    width: 15px;
    height: 15px;
  }
  .privacy__close svg path {
    fill: #fff;
  }
  .privacy__list {
    gap: 8px;
    padding: 0;
    margin: 0;
  }
  .privacy__text {
    margin: 0;
    font-size: 13px;
  }
  .privacy__block__title {
    font-size: 18px;
    margin: 0 0 32px 0;
    text-align: center;
    padding: 0;
  }
  .privacy__title_small {
    align-items: self-start;
    padding: 0;
    margin-bottom: 20px;
  }
  .privacy__title_small h2 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  .privacy__list__title {
    margin: 0;
    padding: 0;
  }
  .privacy__title__number {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  .privacy__list__number {
    padding: 0;
    margin: 0;
  }
  .privacy__list__number_uncolored {
    margin: 0;
    padding: 0;
  }
  .main__text{
    font-size: 13px;
    padding: 5px;
    margin: 0;
  }
}
</style>