export default {
    actions:{
        getNewPosition(ctx, currentSlide = 0){
            ctx.commit('updatePosition', currentSlide)
        },
        getNewMenuState(ctx, currentState = 'closed'){
            ctx.commit('updateMenuState', currentState)
        },
        setNewCarouselBody(ctx, currentState){
            ctx.commit("updateCarouselBody", currentState)
        }
    },
    mutations:{
        updatePosition(state, slide){
            state.currentSlide = slide
        },
        updateMenuState(state, newState){
            state.menuState = newState
        },
        updateCarouselBody(state, newBody){
            state.carouselBody = newBody
        }
    },
    state:{
        currentSlide: 0,
        menuState: 'closed',
        carouselBody: '',
    },
    getters:{
        getCurrentPos(state){
            return state.currentSlide
        },
        getCurrentMenuState(state){
            return state.menuState
        },
        getCarouselBody(state){
            return state.carouselBody
        }
    }
}