<template>
  <div class="icons">
    <div class="icons__wrapper">
      <a target="_blank" rel="nofollow noopener" href="https://app.charla.pro/auth/sign-in" class="account">
        <div class="account__wrapper">
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22 17V18H20V17C20 15.35 18.65 14 17 14H5C3.35 14 2 15.35 2 17V18H0V17C0 14.24 2.24 12 5 12H17C19.76 12 22 14.24 22 17ZM6 5C6 2.24 8.24 0 11 0C13.76 0 16 2.24 16 5C16 7.76 13.76 10 11 10C8.24 10 6 7.76 6 5ZM8 5C8 6.65 9.35 8 11 8C12.65 8 14 6.65 14 5C14 3.35 12.65 2 11 2C9.35 2 8 3.35 8 5Z"
              fill="black" />
          </svg>
        </div>
      </a>
      <div class="chat" @click="setFormState(true)">
        <div class="chat__wrapper">
          <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14 0H8C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16H14C18.42 16 22 12.42 22 8C22 3.58 18.42 0 14 0ZM14 14H8C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2H14C17.31 2 20 4.69 20 8C20 11.31 17.31 14 14 14ZM7 5H15V7H7V5ZM7 9H11V11H7V9Z"
              fill="white" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CharlaIcons",
  methods: {
    ...mapActions(['setFormState']),
  }
}
</script>

<style scoped lang="scss">
.icons {
  position: fixed;
  display: grid;
  place-items: center;
  right: 20px;
  top: 24px;
  width: 72px;
  height: 100%;
  z-index: 2;

  &__wrapper {
    position: relative;
    height: 100%;
  }
}

.account {
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
  top: 0;
  z-index: 9999;
  background-color: white;
  width: 72px;
  height: 72px;
  border-radius: 0 0 0 44px;
  padding: 8px 8px 24px 24px;

  &__wrapper {
    padding: 11px 9px;
    display: flex;
    justify-content: center;

    &>svg>path {
      transition: 300ms ease all;
    }

    &:hover>svg>path {
      fill: var(--purple);
    }
  }

}

.chat {
  position: absolute;
  border-radius: 44px 0 0 0;
  padding: 24px 8px 8px 24px;
  width: 72px;
  height: 72px;
  right: 50%;
  transform: translate(calc(50% + 5px), 0);
  bottom: 48px;
  background-color: white;
  z-index: 1;


  &__wrapper {
    background-color: black;
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 12px 9px;
    border-radius: 24px;
    transition: 300ms ease all;

    &:hover {
      background-color: var(--purple);
    }
  }
}
</style>