<template>
    <div class="cookie__popup" v-show="getCookiePopup">
        <div class="cookie__background" @click="closeCookie()"></div>
        <div class="cookie__close" @click="closeCookie()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
                    fill="black" />
            </svg>
        </div>
        <div class="cookie__wrapper">
            <div class="cookie__container">
                <div class="cookie__title">{{ $t("cookiePopup.mainTitle") }}</div>
                <div class="cookie__block">
                    <div class="cookie__title_small">{{ $t("cookiePopup.title1") }}</div>
                    <p class="cookie__text">{{ $t("cookiePopup.block1.text1") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block1.text2") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block1.text3") }}</p>
                </div>
                <div class="cookie__block">
                    <div class="cookie__title_small">{{ $t("cookiePopup.title2") }}</div>
                    <p class="cookie__text">{{ $t("cookiePopup.block2.text1") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block2.text2") }}</p>
                </div>
                <div class="cookie__block">
                    <div class="cookie__title_small">{{ $t("cookiePopup.title3") }}</div>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text1") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text2") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text3") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text4") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text5") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text6") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text7") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text8") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block3.text9") }}</p>
                </div>
                <div class="cookie__block">
                    <div class="cookie__title_small">{{ $t("cookiePopup.title4") }}</div>
                    <p class="cookie__text">{{ $t("cookiePopup.block4.text1") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block4.text2") }}</p>
                    <p class="cookie__text">{{ $t("cookiePopup.block4.text3") }}</p>
                </div>
                <div class="cookie__block">
                    <div class="cookie__title_small">{{ $t("cookiePopup.title5") }}</div>
                    <p class="cookie__text">{{ $t("cookiePopup.block5.text1") }}</p>
                </div>
                <div class="cookie__block">
                    <div class="cookie__title_small">{{ $t("cookiePopup.title6") }}</div>
                    <p class="cookie__text">{{ $t("cookiePopup.block6.text1") }}</p>
                </div>
                <div class="cookie__block">
                    <div class="cookie__title_small">{{ $t("cookiePopup.title7") }}</div>
                    <p class="cookie__text" v-html='$t("cookiePopup.block7.text1")'></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tr from "@/i18n/translation";
import { mapGetters, mapActions } from "vuex";
export default {
    methods: {
        ...mapActions(['setCookiePopup']),
        closeCookie() {
            const cookieWrapper = document.querySelector('.cookie__wrapper');
            const cookieBackground = document.querySelector('.cookie__background');
            const cookieClose = document.querySelector('.cookie__close')

            cookieWrapper.classList.add('cookie__wrapper__disable');
            cookieBackground.classList.add('cookie__background__disable');
            cookieClose.classList.add('cookie__close__disable')


            setTimeout(() => {
                cookieWrapper.classList.remove('cookie__wrapper__disable');
                cookieBackground.classList.remove('cookie__background__disable');
                cookieClose.classList.remove('cookie__close__disable')
                this.setCookiePopup(false)
            }, 500)
        }
    },
    computed: {
        ...mapGetters(["getCookiePopup"]),
        Tr() {
            return Tr
        }
    }
}
</script>

<style lang="scss" scoped>
.cookie {
    &__background {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 9999;
        animation: 500ms ease forwards __fadeIn;

        &__disable {
            animation: 500ms ease forwards __fadeOut;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;

        & .cookie__text:last-of-type {
            padding: 0;
        }
    }

    &__close {
        position: fixed;
        background-color: white;
        top: 48px;
        left: calc(50% + 418px);
        width: 48px;
        height: 48px;
        border-radius: 50%;
        z-index: 100000;
        transform: translate(50%, 0);
        display: grid;
        place-items: center;
        cursor: pointer;
        animation: 500ms ease forwards __slideUpClose;

        &__disable {
            animation: 500ms ease forwards __slideDownClose;
        }
    }

    &__wrapper {
        padding: 64px;
        position: fixed;
        left: 50%;
        top: 24px;
        transform: translate(-50%, 0);
        max-width: 836px;
        width: 100%;
        background-color: white;
        height: calc(100vh - 48px);
        overflow: hidden scroll;
        z-index: 10000;
        border-radius: 44px;
        animation: 500ms ease forwards __slideUp;

        &__disable {
            animation: 500ms ease forwards __slideDown;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }

    &__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 120%;
        color: #000;

        &_small {
            font-weight: 600;
            font-size: 22px;
            line-height: 127%;
            color: #000;
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 0 0 32px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: #000;
        padding: 0 0 16px;
    }
}

@keyframes __fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes __fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes __slideUp {
    from {
        transform: translate(-50%, 120vh);
    }

    to {
        transform: translate(-50%, 0);
    }
}

@keyframes __slideDown {
    from {
        transform: translate(-50%, 0);
    }

    to {
        transform: translate(-50%, 120vh);
    }
}

@keyframes __slideUpClose {
    from {
        transform: translate(50%, 120vh);
    }

    to {
        transform: translate(50%, 0);
    }
}

@keyframes __slideDownClose {
    from {
        transform: translate(50%, 0);
    }

    to {
        transform: translate(50%, 120vh);
    }
}

@media screen and (max-width: 660px) {
    .cookie__wrapper {
        width: calc(100vw - 16px);
        height: calc(100vh - 16px);
        padding: 0;
        top: 8px;
    }

    .cookie__popup {
        width: 100%;
        height: 100%;
    }

    .cookie__container {
        gap: 30px;
        padding: 20px;
    }

    .cookie__title {
        font-size: 22px;
        margin: 0;

        &_small {
            font-size: 18px;
            margin: 0;
        }
    }

    .cookie__close {
        background: #000;
        top: 18px;
        right: 40px;
        left: unset;
        width: 36px;
        height: 36px;
    }

    .cookie__close svg {
        width: 15px;
        height: 15px;
    }

    .cookie__close svg path {
        fill: #fff;
    }

    .cookie__text {
        margin: 0;
    }
}
</style>