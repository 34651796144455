<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <nav class="footer__socials">
        <a :href="$t('charlaTg')" target="_blank" class="footer__socials__item">
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.5387 0.159118L0.591584 5.87106C-0.0501582 6.13706 -0.267213 6.66974 0.436474 6.95883L4.52761 8.16645L14.4194 2.48816C14.9596 2.13169 15.5125 2.22674 15.0367 2.61889L6.54093 9.76377L6.27406 12.7875C6.52125 13.2543 6.97385 13.2565 7.26255 13.0245L9.61303 10.9587L13.6386 13.7586C14.5736 14.2727 15.0823 13.9409 15.2835 12.9986L17.9239 1.3857C18.198 0.22578 17.7305 -0.285295 16.5387 0.159118Z"
              fill="white" />
          </svg>
        </a>
        <!--        <p class="footer__next-page__text" v-if="this.getCurrentPos === 0">{{ $t("footer.title") }}</p>-->
      </nav>
      <a class="footer__start" rel="nofollow noopener"  :href="$t('charlaRegLink')" target="_blank">
        <span class="footer__start__wrapper">
          <span class="footer__start__content">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0H8V6H0V8H8V14H10V8H18V6H10V0Z" fill="white" />
            </svg>
            <p class="footer__start__text">{{ $t("footer.btn") }}</p>
          </span>
        </span>
      </a>
      <div class="footer__next-page" @click="moveToNextSlide">
        <div class="footer__next-page__button">
          <div class="footer__next-page__button__wrapper">
            <div class="footer__next-page__button__content" v-for="(item, i) in tellText" :key="i">
              <p v-if="this.getCurrentPos === i">{{ item }}</p>
            </div>
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.42 16H6.58L0 9.42L1.42 8L7 13.58L7 0L9 8.74224e-08V13.58L14.58 8L16 9.42L9.42 16Z"
              fill="black" />
          </svg>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import Tr from "@/i18n/translation";


export default {
  name: "CharlaFooter",
  computed: {
    ...mapGetters(['getCurrentPos']),
    Tr() {
      return Tr
    },
    tellText() {
      return [
        this.$t("footer.screen_name1"),
        this.$t("footer.screen_name2"),
        this.$t("footer.screen_name3"),
        this.$t("footer.screen_name4"),
        this.$t("footer.screen_name5"),
        this.$t("footer.screen_name6"),
        this.$t("footer.screen_name7"),
        this.$t("footer.screen_name8"),
        this.$t("footer.screen_name9"),
        this.$t("footer.screen_name10")
      ]
    }
  },
  methods: {
    moveToNextSlide() {

    },
  },
  mounted() {

  },
  data() {
    return {
    }
  },
}
</script>
<style scoped lang="sass">
.ru-locale
  .footer__next-page__button
    font-size: 12px
    white-space: normal
    text-align: center
</style>
<style scoped lang="scss">
.footer {
  position: fixed;
  left: 0;
  bottom: 24px;
  width: 100%;
  z-index: 1;

  &__wrapper {
    width: 50vw;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 200px;
    position: relative;
    align-items: center;
  }

  &__start {
    white-space: nowrap;
    position: absolute;
    left: 200px;
    display: grid;
    grid-template-columns: 0fr;
    transition: 500ms ease;


    &__wrapper {
      overflow: hidden;
    }

    &__content {
      background-color: black;
      display: flex;
      align-items: center;
      border-radius: 24px;
      gap: 16px;
      padding: 13px 24px;
      transition: 300ms ease background-color;

      &:hover {
        background-color: var(--purple);
      }
    }

    &_active {
      grid-template-columns: 1fr;
    }

    &__content {}


    &__text {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #fff;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 8px;

    &__text {
      font-size: 12px;
    }

    &__item {
      width: 40px;
      height: 40px;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      background-color: black;
      transition: 300ms ease background-color;

      &:hover {
        background-color: var(--purple);
      }
    }
  }

  &__next-page {
    position: relative;
    white-space: nowrap;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 16px;
    transform: translate(84px, 0);

    &__text {
      font-weight: 400;
      font-size: 12px;
      user-select: none;
    }

    &__button {
      font-weight: 600;
      font-size: 14px;
      padding: 13px 24px;
      background-color: white;
      border: 1px solid #000;
      border-radius: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      white-space: nowrap;
      transition: 400ms ease all;
      user-select: none;
      overflow: hidden;
      height: 44px;

      & p {
        white-space: nowrap;
      }

      &:hover {
        background-color: black;
        color: white;
      }

      & svg path {
        transition: 400ms ease fill;
      }

      &:hover svg path {
        fill: white;
      }

      &>svg {
        transition: 300ms all ease;
      }

      &_opened {
        gap: 24px;

        & .footer__next-page__button__wrapper {
          grid-template-rows: 1fr;
          grid-template-columns: 1fr;
        }
      }

      &_closed {
        gap: 0;

        & .footer__next-page__button__wrapper {
          grid-template-rows: 0fr;
          grid-template-columns: 0fr;
        }
      }

      &__wrapper {
        display: grid;
        grid-template-rows: 0fr;
        grid-template-columns: 0fr;
        transition: 300ms all ease;


      }

      &__content {
        overflow: hidden;
      }
    }
  }
}

.rotate__arrow {
  transform: rotate(180deg);
}


@keyframes __fullWidth {
  from {
    max-width: 0;
    padding: 0;
  }

  to {
    max-width: 100%;
    padding: 13px 24px;
  }

}

@media screen and (max-width: 1600px) {
  .footer__wrapper {
    padding: 0 0 0 168px;
  }

  .footer__start {
    left: 168px;
  }
}

@media screen and (max-width: 1440px) {
  .footer__wrapper {
    padding: 0 0 0 56px;
  }

  .footer__start {
    left: 56px;
  }
}

@media screen and (max-width: 1300px) {
  .footer__next-page__button_opened {
    gap: 10px;
  }

  .footer__next-page__button {
    font-size: 13px;
    white-space: normal;
  }
}

@media screen and (max-width: 1100px) {
  .footer__wrapper {
    padding: 0 0 0 24px;
  }

  .footer__start {
    left: 24px;
  }
}
</style>