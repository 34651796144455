import { createRouter, createWebHistory, RouterView } from 'vue-router'
import HomeView from '../views/main.vue'
import faqPage from '../views/faq.vue'
import pp from '../views/pp.vue'
import reviewsPage from '../views/reviews.vue'
import Translation from "@/i18n/translation";
import termsOfUse from "@/views/termsOfUse.vue";
import cookiePolicy from "@/views/cookiePolicy.vue";
import NotFoundComponent from "@/components/NotFoundComponent.vue";

const routes = [
    {
        path: '/',
        component: RouterView,
        beforeEnter: Translation.routeMiddleware,
        children: [
            {
                path: '',
                name: 'home',
                component: HomeView
            },
            {
                path: 'faq',
                name: 'faq',
                component: faqPage
            },
            {
                path: 'reviews',
                name: 'reviews',
                component: reviewsPage
            },
            {
                path: 'privacy-policy',
                name: 'privacy-policy',
                component: pp,
                // beforeEnter: Translation.routeMiddlewarePP
            },
            {
                path: 'terms-and-conditions',
                name: 'terms-and-conditions',
                component: termsOfUse,
                // beforeEnter: Translation.routeMiddlewarePP
            },
            {
                path: 'cookie-policy',
                name: 'cookie-policy',
                component: cookiePolicy,
                // beforeEnter: Translation.routeMiddlewarePP
            },
            {
                path: '/:pathMatch(.*)',
                component: NotFoundComponent
            }
        ]
    },
    // {
    //     path: '/privacy-policy',
    //     redirect: to => {
    //         const locale = Translation.guessDefaultLocale(to);
    //         return `/${locale}/privacy-policy`;
    //     }
    // },
    // {
    //     path: '/terms-and-conditions',
    //     redirect: to => {
    //         const locale = Translation.guessDefaultLocale(to);
    //         return `/${locale}/terms-and-conditions`;
    //     }
    // },
    // {
    //     path: '/cookie-policy',
    //     redirect: to => {
    //         const locale = Translation.guessDefaultLocale(to);
    //         return `/${locale}/cookie-policy`;
    //     }
    // },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
