<template>
  <div class="spectrograph">
    <div class="audio-play-btn-desk" @click="playPauseAudio" :class="{ 'audio-pause': audioIsPaused }">
      <div class="pause-icon">
        <span></span><span></span>
      </div>
      <div class="play-icon">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 7L4 14V0L14 7Z" fill="#B561F7"/>
        </svg>
      </div>
    </div>
    <div id="audio_wave"></div>
  </div>
</template>
<script>
import WaveSurfer from "wavesurfer.js";

export default {
  name: "audioWave",
  data(){
    return{
      audioIsPaused: false
    }
  },
  props: {
    file: {
      type: String
    }
  },
  methods:{
    playPauseAudio(){
      this.audioWave.playPause();
      this.audioIsPaused = !this.audioIsPaused;
    },
    resetAudio() {
      this.audioIsPaused = false;
    }
  },
  mounted() {
    this.audioWave = WaveSurfer.create({
      container: '#audio_wave',
      waveColor: '#fff',
      progressColor: '#D9AFFA',
      cursorWidth: 0,
      barRadius: 0,
      barWidth: 1,
      barHeight: 50,
      barGap: 3,
      height: 30,
      normalize: true,
      interact: true,
      url: this.file
    });
  },
  watch: {
    file(newFile) {
      if (this.audioWave) {
        this.audioWave.load(newFile);
      }
    }
  }
}
</script>
<style lang="sass" scoped>
#audio_wave
  width: 100%
.spectrograph
  display: flex
  align-items: center
  width: 100%
  gap: 8px
  background: #B561F6
  border-radius: 32px
  box-sizing: border-box

.audio-play-btn-desk
  flex: none
  display: flex
  align-items: center
  justify-content: center
  width: 48px
  height: 48px
  border-radius: 50%
  background: #fff
  cursor: pointer

.audio-play-btn-desk .pause-icon
  display: none
  justify-content: center
  align-items: center
  gap: 2px

.pause-icon span
  width: 3px
  height: 12px
  background: #B561F6


.play-icon
  display: block

.audio-pause .play-icon
  display: none

.audio-pause .pause-icon
  display: flex
@media screen and (max-width: 660px)
  .spectrograph
    display: flex
    align-items: center
    max-width: 296px
    width: 100%
    height: 56px
    gap: 8px
    margin: auto auto 32px auto
    background: #B561F6
    border-radius: 32px
    padding: 8px 16px 8px 8px
    box-sizing: border-box

  .audio-play-btn .pause-mobile-icon
    display: none
    justify-content: center
    align-items: center
    gap: 2px
  .pause-mobile-icon span
    width: 3px
    height: 12px
    background: #B561F6
  .play-icon
    display: block
  .audio-pause .play-mobile-icon
    display: none
  .audio-pause .pause-mobile-icon
    display: flex

  #waveform
    width: 100%
  .audio-play-btn
    flex: none
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    border-radius: 50%
    background: #fff
    cursor: pointer
</style>