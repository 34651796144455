<template>
  <router-view/>
</template>

<style lang="scss">
body {
  font-family: 'Unbounded', sans-serif;
}

@media screen and (max-width: 1280px) {
  #app {
    //display: none;
  }
}
</style>
