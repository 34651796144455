<template>
  <div class="block prices" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <div class="block__title">
        <span>{{ $t("CharlaPrice.title_part1") }}</span>
        <span><b>{{ $t("CharlaPrice.title_part2") }}</b></span>
      </div>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="price__carousel">
        <div class="price__carousel__item price__carousel__item_black">
          <div class="price__carousel__item__title__wrapper">
            <div class="price__carousel__item__title">{{ $t("CharlaPrice.Rates.rate1.title") }}</div>
            <p class="price__carousel__item__price">
              <span>{{ $t("CharlaPrice.Rates.rate1.price") }}</span>
              {{ $t("CharlaPrice.Rates.rate1.time") }}
            </p>
            <div class="price__carousel__item__slogan">
              {{ $t("CharlaPrice.Rates.rate1.spec_li") }}
            </div>
          </div>
          <ul class="price__carousel__item__list unlimited">
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate1.li1") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate1.li2") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate1.li3") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate1.li4") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate1.li5") }}
              <div class="price__carousel__item__list__item__beta">
                beta
              </div>
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate1.li6") }}
              <div class="price__carousel__item__list__item__beta">
                beta
              </div>
            </li>
          </ul>
          <a class="price__carousel__item__link" href="https://app.charla.pro/sign-in/" rel="nofollow noopener" target="_blank">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0H8V6H0V8H8V14H10V8H18V6H10V0Z" fill="white" />
            </svg>
            {{ $t("CharlaPrice.Rates.rate1.btn") }}
          </a>
        </div>
        <div class="price__carousel__item price__carousel__item_purple">
          <div class="price__carousel__item__title__wrapper">
            <div class="price__carousel__item__title" v-html="$t('CharlaPrice.Rates.rate2.title')"></div>
            <p class="price__carousel__item__price">
              {{ $t("CharlaPrice.Rates.rate2.time") }}
            </p>
            <div class="price__carousel__item__slogan">
              {{ $t("CharlaPrice.Rates.rate2.spec_li") }}
            </div>
          </div>
          <ul class="price__carousel__item__list">
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate2.li1") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate2.li2") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate2.li3") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="black" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate2.li4") }}
            </li>
          </ul>
          <div class="price__carousel__item__link" @click="setFormState(true)">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0H8V6H0V8H8V14H10V8H18V6H10V0Z" fill="white" />
            </svg>
            {{ $t("CharlaPrice.Rates.rate2.btn") }}
          </div>
        </div>
        <div class="price__carousel__item price__carousel__item_gray">
          <div class="price__carousel__item__title__wrapper">
            <div class="price__carousel__item__title">{{ $t("CharlaPrice.Rates.rate3.title") }}</div>
            <p class="price__carousel__item__price" style="color: #000">
              <span style="color: #B561F6">{{ $t("CharlaPrice.Rates.rate3.price") }}</span>
              {{ $t("CharlaPrice.Rates.rate3.time") }}
            </p>
            <div class="price__carousel__item__slogan">
              {{ $t("CharlaPrice.Rates.rate3.spec_li") }}
            </div>
          </div>
          <ul class="price__carousel__item__list">
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate3.li1") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate3.li2") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate3.li3") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate3.li4") }}
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate3.li5") }}
              <div class="price__carousel__item__list__item__beta">
                beta
              </div>
            </li>
            <li class="price__carousel__item__list__item">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.41L7.39 14L0 6.63L1.41 5.22L7.38 11.18L18.59 0L20 1.41Z" fill="#B561F7" />
              </svg>
              {{ $t("CharlaPrice.Rates.rate3.li6") }}
              <div class="price__carousel__item__list__item__beta">
                beta
              </div>
            </li>
          </ul>
          <a rel="nofollow noopener" href="https://app.charla.pro/sign-in/" target="_blank" class="price__carousel__item__link">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0H8V6H0V8H8V14H10V8H18V6H10V0Z" fill="white" />
            </svg>
            {{ $t("CharlaPrice.Rates.rate3.btn") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import Tr from "@/i18n/translation";
export default {
  name: "CharlaPrice",
  computed: {
    Tr() {
      return Tr
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
        } else {
          splideContainer.dataset.stopScroll = 'true'
        }
      })
    }, {
      threshold: 1
    });

    let carouselItem = [...document.querySelectorAll('.price__carousel__item')];
    carouselItem = carouselItem[carouselItem.length - 1]
    observer.observe(carouselItem)
  },
  methods:{
    ...mapActions(['setFormState'])
  }
}
</script>

<style scoped lang="scss">
  .block__right{
    position: relative;
    z-index: 2;
  }
  .prices .block__title{
    transition: 500ms ease opacity;
  }

  .price__carousel{
    position: absolute;
    display: flex;
    gap: 16px;
    left: -280px;
    top: 50%;
    transform: translate(0, -50%);
    transition: 500ms ease left;

    &__item{
      width: 400px;
      height: auto;
      border-radius: 32px;
      padding: 32px;
      display: grid;
      grid-template: auto 1fr auto / 1fr;
      gap: 32px;


      &__link{
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
        padding: 13px 0;
        background-color: black;
        border-radius: 24px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        text-transform: uppercase;
        color: #fff;
      }

      &__slogan{
        grid-area: 2 / 1 / 2 / span 2;
      }

      &__title{
        font-weight: 600;
        font-size: 22px;
        line-height: 127%;
        color: #fff;

        &__wrapper{
          display: grid;
          grid-template: auto auto / 1fr auto;
          align-items: center;
          row-gap: 8px;
        }
      }

      &__price{
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: var(--light-bg);

        & > span{
          font-weight: 600;
          font-size: 22px;
          line-height: 127%;
          color: var(--purple);
        }
      }

      &__list{
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__item{
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #fff;
          display: grid;
          grid-template: 1fr / 20px auto 1fr;
          align-items: center;
          gap: 16px;

          &__beta{
            font-weight: 600;
            font-size: 10px;
            line-height: 130%;
            padding: 4px 8px;
            border-radius: 24px;
            background-color: var(--red);
            width: fit-content;
            color: #fff;
          }
        }
      }

      &_black{
        background-color: black;

        & .price__carousel__item__slogan{
          color: var(--purple);
          font-weight: 600;
          font-size: 14px;
          line-height: 157%;
        }

        & > .price__carousel__item__link{
          background-color: var(--purple);
        }
      }

      &_purple{
        background-color: var(--purple);

        & .price__carousel__item__slogan{
          color: black;
          font-weight: 600;
          font-size: 14px;
          line-height: 157%;
        }
      }

      &_gray{
        background-color: var(--light-bg);

        & .price__carousel__item__slogan{
          color: var(--purple);
          font-weight: 600;
          font-size: 14px;
          line-height: 157%;
        }

        & .price__carousel__item__list{
          justify-content: flex-start;
          gap: 16px;
        }
        & .price__carousel__item__title{
          color: black;
        }

        & .price__carousel__item__list__item{
          color: black;
        }
      }
    }
  }


  @media screen and (max-width: 1300px){
    .price__carousel {
      left: -200px;
    }
  }
  @media screen and (max-height: 750px) {
    .price__carousel__item {
      row-gap: 8px;
      padding: 16px 32px;
    }
    .price__carousel__item_gray .price__carousel__item__list{
      gap: 8px;
    }
    .price__carousel__item__list{
      gap: 8px;
    }
    .price__carousel{
      top: 53%;
    }
  }
</style>