<template>
  <div class="terms__popup" v-show="getTermsState">
    <div class="terms__background" @click="closeTerms()"></div>
    <div class="terms__close" @click="closeTerms()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
            fill="black"/>
      </svg>
    </div>
    <div class="terms__wrapper">
      <div class="terms__container li-eng" v-if="Tr.currentLocale === 'en'">
        <div class="terms__title">Public offer for use of the Charla service</div>
        <div class="terms__block">
          <p class="terms__text">This offer (hereinafter referred to as the “Offer”) is directed to any individual
            or legal entity, individual entrepreneur and is a public offer of TeamLead LLC to conclude a license
            agreement for the right to use the Charla service on the terms of a simple (non-exclusive) license
            (hereinafter referred to as the “Agreement”).</p><br>
          <p class="terms__text">The Agreement is deemed concluded and becomes effective from the moment the User
            commits the actions
            stipulated by this Offer and means the acceptance without modification by the User of all terms and
            conditions of the Offer without any exceptions or limitations on the terms of accession.</p><br>
          <p class="terms__text">This Offer determines the rules of use of the Charla Service and it is available for
            review by an
            unlimited number of individuals on the information resources of the Licensor,
            <a class="colored__text" href="https://charla-ai.com/">https://charla-ai.com/</a>.</p>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">1. Definitions</div>
          <div class="main__grid">
            <p class="main__text">Offer</p>
            <p class="main__text">Licensor's offer addressed to any individual who is willing to conclude the Agreement
              on the terms and conditions specified in this offer.</p>
            <p class="main__text">Acceptance</p>
            <p class="main__text">full and unconditional acceptance by the User of the terms and conditions of the offer
              made by registration.</p>
            <p class="main__text">Licensor</p>
            <p class="main__text">TeamLead LLC, possessing the exclusive right to the Charla Service.</p>
            <p class="main__text">User</p>
            <p class="main__text">an individual or legal entity, individual entrepreneur, who has accepted the
              Offer.</p>
            <p class="main__text">Charla service</p>
            <p class="main__text">computer software in the form of a set of commands intended for transcribing
              (transcribing audio files into text): - Telegram messenger bot <a class="colored__text" href="t.me/charla_ru_bot">t.me/charla_ru_bot</a> — API (provided by the
              Licensor upon direct request).</p>
            <p class="main__text">Website</p>
            <p class="main__text">Charla's website having the Internet address <a class="colored__text"
                                                                                  href="https://charla-ai.com/">https://charla-ai.com/</a>,
              and its subdomains.</p>
            <p class="main__text">Licensor's Server</p>
            <p class="main__text">a complex of computers controlled by Licensor in order to ensure the functioning of
              the Service for the User.</p>
            <p class="main__text">License</p>
            <p class="main__text">the right to use the Service in the scope and within the limits established by this
              Agreement and the Pricing for the Licenses on the terms of a simple (non-exclusive) license, with the
              Licensor reserving the right to grant licenses to other parties.</p>
            <p class="main__text">License Fee</p>
            <p class="main__text">a fee for providing the License, which the User undertakes to pay to the Licensor in
              accordance with this Agreement.</p>
            <p class="main__text">Pricing</p>
            <p class="main__text">the amount of the license fee for providing access to the Charla service, which the
              User undertakes to pay to the Licensor by selecting the available Pricing on the Website at <a
                  class="colored__text" href="https://charla-ai.com/">https://charla-ai.com/</a>.</p>
            <p class="main__text">Payment Period</p>
            <p class="main__text">the time period paid by the User according to the selected Pricing for the use of the
              Charla Service.</p>
          </div>
          <br>
          <p class="terms__text">Other terms and definitions used in the Offer have meaning and are subject to
            interpretation according to the provisions of this Offer, and in the case of absence of such provisions in
            the Offer — in accordance with the established practice of use of these terms and definitions in civil
            circulation, as well as in accordance with the legislation of the Russian Federation.</p>

        </div>
        <div class="terms__block">
          <div class="terms__block__title">2. FREE ACCESS</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Each User receives 5 days of free unlimited access to the functionality of the Charla
                Service from the moment of registration on the website.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The User may receive 5 extra days of free unlimited access in exchange for leaving
                feedback regarding the work with the Service.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor has the right to set a different period of free access duration
                unilaterally by posting the relevant information on the Website.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The free access expires after the use of the 5 days of the initial period provided, as
                well as possible additional 5 days gained for the feedback. The non-used period of free access is not
                prolonged.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">3. PAID ACCESS. PAYMENT</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">To use the Charla Service after the free access period expires, the User chooses a
                Pricing plan.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Pricing plan is paid by the User via electronic payment systems.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The day of payment of the Pricing plan is considered to be the day of receipt of funds
                to the Licensor's settlement account.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">All settlements of the Parties under this Agreement are to be made in Russian
                rubles.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">All bank or other commission fees connected with the payment of the License Fee shall
                be paid by the User.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">After the expiry of the Payment Period, the Pricing plan may be prolonged for a similar
                period, provided that the User's Personal Account has sufficient funds to pay the Pricing plan for the new
                period, unless the User has restricted access to the Charla Service on its own.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The User has the right to cancel the selected Pricing plan, except for the cases of
                changing the Pricing Plan to a more expensive within 7 (seven) calendar days from the date of activation of
                the Pricing plan, by submitting a request to the technical support service. Based on such a request, the
                Licensor returns the debited funds for the incorrectly selected Pricing plan to the User's Personal Account,
                excluding the Licensor's fee at the time of the actual provision of access to the Charla Service.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Termination of the User's use of the paid Pricing plan, including in case of violation
                of the terms and conditions of the Agreement, is not grounds for refunding the paid Pricing plan.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor has the right to change the Pricing plan unilaterally, without prior
                agreement with the User; the cost of the already paid Pricing remains the same. The Licensor notifies the
                User about changes in the Pricing plan by posting information on the Website. The changes come into effect
                on the next calendar day after the information is posted on the Website.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">License fee is not subject to VAT, based on subparagraph 26 of paragraph 2 of Article
                149 of the Tax Code of the Russian Federation.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">4. RIGHTS AND OBLIGATIONS OF THE PARTIES</div>
          <br>
          <p class="terms__list"><b>User has the right to:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Purchase a simple non-exclusive right to use the Charla Service in accordance with the
                procedure and on the terms and conditions stipulated by this Agreement.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Provide round-the-clock access to the Charla Service, except for the time of performing
                preventive maintenance in accordance with the terms and conditions of this Agreement, under the condition of
                timely payment of the License Fee.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Withdraw from using the Charla Service in accordance with the procedure set out in this
                Agreement.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Submit proposals to the Licensor for improving and upgrading the Charla Service in
                Russian.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Modify the Charla Service settings within the limits provided to the User.
              </p>
            </li>
          </ul>
          <br>
          <p class="terms__list"><b>The user is obligated to:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Use the Charla Service independently and do not transfer the rights to use the Charla
                Service and copies to third parties.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Timely pay the license fee in the amount and within the terms stipulated by the chosen
                Pricing plan.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Ensure the safety of the Charla Service access parameters received from the Licensor in
                order to protect them from unauthorized access by unauthorized persons.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Use the Charla Service only in compliance with all the terms and conditions of this
                Agreement.</p>
            </li>
          </ul>
          <br>
          <p class="terms__list"><b>The user is not allowed to:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Use the Charla Service to commit illegal activities, including spam mailings, sending
                threats and offenses, distribution of false advertising, calls to violence, certain types of information and
                materials distributed via the information and telecommunication network “Internet”, whose distribution is
                prohibited in the Russian Federation, and others.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Access study, edit the source code of the Charla Service, and expose the technological
                principles of the Charla Service.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Copy and duplicate the Charla Service Program or any elements thereof and store the
                Charla Service Program on any media for the purpose of transmission to third party(ies), as well as publish
                the Charla Service Program, allowing third party(ies) to copy it.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Lease Charla Service or other temporary use to third party(ies) for the purpose of
                profit making by the User and/or the third party(ies).</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Attempt to overcome the technical limitations of the Charla Service.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Place on the Charla Service and/or distribute malware (viruses) using the Charla
                Service.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Share passwords and logins used to access the Charla Service with third parties and
                ensure their confidentiality.</p>
            </li>
          </ul>
          <br>
          <p class="terms__list"><b>The licensor has the right to:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Block the User's access to the Charla Service if the User violates any terms and
                conditions of this Agreement, as well as at the request of a court and/or other authorized state authorities
                of the Russian Federation. Such blocking is not subject to charging of the Pricing Plan.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Temporarily block the User's access to the Charla Service in case of arrears.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Suspend the User's access to the Charla Service if the User violates the terms and
                conditions of this Agreement.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Require from the User additional contact information, as well as information about
                certificates and (or) licenses, if there are reasons to believe that the User does not follow the laws of
                the Russian Federation or in case of a request from authorized law enforcement agencies, and if the User
                refuses to provide this information, the Licensor has the right to limit the User's right of access to the
                Charla Service.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Improve the Charla Service without the User's agreement and introduce various updates
                to the Charla Service.
              </p>
            </li>
          </ul>
          <br>
          <p class="terms__list"><b>Licensor is obligated to:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Provide the User with a simple non-exclusive right to use the Charla Service in
                accordance with the procedure and on the terms and conditions stipulated in this Agreement.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Ensure the functioning of the Charla Service and the User's access to the Charla
                Service.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Notify the User of significant updates to the Charla Service and changes in the terms
                of transfer of rights to use the Charla Service via e-mail and/or publication on the Licensor's Website in
                the Russian language.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Inform the User of any changes to the Pricing plans via e-mail and/or publication on
                the Licensor's Website in the Russian language.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">5. AGREEMENTS FOR USE OF THE CHARLA SERVICE</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">When paying the relevant Pricing plan, the User is granted the right to use the Charla
                Service by reproduction, limited to installing, running, setting up the Charla Service.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">It is not allowed to distribute the Service. Distribution of the Service means
                particularly: providing access to third parties to the components of the Service reproduced in any form,
                including by selling, renting, leasing or lending.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Access to the Charla Service is limited to the payment period of the User's current
                Pricing plan.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">If the User does not cancel the selected Pricing plan during 7 (seven) calendar days
                from the date of payment for the selected Pricing plan, the License to use the Charla Service is considered
                to be provided to the User in full properly.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">From the date of the Agreement conclusion and for the entire period of the Charla
                Service use, the User acquires the right to receive basic technical support from the Licensor for the fixing
                of any errors detected by the User in the Charla Service:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">if the error interferes with the operation of the Charla Service — within 3 (three) days after
                receiving notification of the error from the User;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">if the error doesn't prevent the operation of the Charla Service — within 20 (twenty) days after
                receiving notification of the error from the User.
              </p>
            </li>
          </ul>
          <br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The User is informed and accepts that the Licensor may prolong the error correction
                period or remove certain functions from the Service altogether if it establishes that the error correction
                depends on other persons, since the Charla Service cooperates closely with programs of other developers, the
                operating system and hardware resources of the User's personal computer.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">6. SUPPLEMENTARY SERVICES</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor may provide Supplementary Services to the User for an extra fee:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">training of the User's staff in the Charla Service;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">technical support of the Program to the extent and at the prices specified in the Pricing plan;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">changing the functionality of the Charla Service, introducing new features and capabilities of the
                Charla Service, automation of previously non-automated manual operations, and other modifications that
                go beyond the Charla Service adaptation (Charla Service modification).
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The training services of the User on the Charla Service include online training
                consultations, other services specified in the Pricing plan on the Website.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Technical support service provides technical and training consulting, as well as other
                services specified in the Pricing plan on the Website. The period of providing technical support services
                within paid pricing plans is set in months, calculated from the date of payment of the invoice by the
                User.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Technical support is provided only for one unit of the installed Software.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The price for Supplementary Services is determined in accordance with the Pricing plan
                selected by the User.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The User shall pay the bill for the Supplementary Services:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">by wire transfer to the Licensor's settlement account according to the invoice issued by the
                Licensor;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">using electronic payment systems.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor has the right to suspend the rendering of Supplementary Services because
                of the lack of access to the User's hardware, information systems of the User and other resources that are
                necessary for the proper provision of services, until the moment of providing the appropriate access. The
                payment for the paid period shall not be reduced in this case.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">7. LIABILITY OF THE PARTIES</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Parties are liable for non-compliance with the terms and conditions of this
                Agreement in accordance with the laws of the Russian Federation.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The User is responsible: for any of their own actions and the actions of third
                party(ies) in the Charla Service to whom the User has provided access to the Charla Service, including
                dealing with the settings, templates and data of the Software; for the manner in which they conduct their
                operations using the Charla Service; for the safety of the User's access parameters to the Charla Service
                and for any losses that may be caused as a result of unauthorized use of their access; for posting any
                materials, including advertising and content that are the subject of an unauthorized use of the Charla
                Service; for posting any materials, including advertising and materials that are subject to copyright under
                the legislation of the Russian Federation.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor doesn't guarantee the possibility to fix the results of individual actions
                of the User and the third party (ies).
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor is not responsible to the User for any damage, any loss of revenue,
                profit, lost revenue, information or savings arising from the use of or inability to use the Charla
                Service.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Licensor is not responsible for non-performance or inappropriate performance of
                obligations under this Agreement, including direct and/or indirect losses of the User, including direct
                and/or indirect losses without limiting the User because of:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">illegal activities of Internet users;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">the existence and/or absence of problems in setting up the Internet connection between the User's
                server and the Server;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">conducting operational and search measures by state authorities, if such measures are not caused by the
                Licensor's illegal activities confirmed by an effective court decision;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">illegal restriction of access to the Website by third parties.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor's responsibility to the User is limited to real damages, but no more than
                the value of the paid Pricing plan for the last month of use of the Charla Service.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Licensor does not guarantee to the User, while using the Charla Service, suitability
                for the User's specific purposes, profitability as a result of using the Charla Service by the User,
                productivity, system integration, uninterrupted functioning, lack of errors, fixing of faults.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">8. CONFIDENTIALITY TERMS</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Parties consider the terms and conditions of this Agreement, as well as all
                information received by one Party from the other Party during its conclusion and execution (hereinafter
                referred to as “Confidential Information”) as confidential.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Parties undertake not to reveal, publish, release, disclose, publicize or otherwise
                make Confidential Information available to any third party(ies) without the prior written authorization of
                each Party.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Each Party will take all necessary measures to protect Confidential Information,
                applying the same measures that a Party applies to protect its own Confidential Information.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">9. PERSONAL DATA</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The User provides the Licensor with voluntary informed agreement to process his/her
                personal data, including his/her name, phone number, e-mail address for the purposes of the Agreement
                execution. By entering into this Agreement, the User confirms that he/she has read and agrees with the
                Licensor's Policy on Personal Data Processing, located at: <a class="colored__text" href="https://charla-ai.com/">https://charla-ai.com/</a>.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Processing of User's Personal Data includes its storage, processing, anonymization and
                destruction.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">IP address, domain name, browser type and operating system, date and time of visit are
                collected and stored by Licensor in an anonymized form to keep statistics.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Licensor uses User's Personal Data for marketing, promotional and informational needs,
                including:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">informing about contests and promotions;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">newsletter and business offers;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">personalization of the site based on statistical data analysis.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Licensor is authorized to send emails, text messages and other messages containing
                news, promotions, event notifications..
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">10. AGREEMENT VALIDITY. AGREEMENT EARLY TERMINATION</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Agreement is considered to be concluded with any individual or legal entity,
                individual entrepreneur from the moment of full and unconditional acceptance of the terms and conditions of
                this Agreement by such individual. From the moment of full and unconditional acceptance of the terms and
                conditions of this Agreement, the specified individual becomes a Party to this Agreement.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">This Agreement is in force from the date of its conclusion.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Agreement may be terminated in the following cases:</p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">by agreement of the Parties at any time;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">on the initiative of any Party with a written notice to the other Party within 30 (thirty) calendar
                days prior to the termination of the Agreement;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">at the initiative of the Licensor immediately and without written notice to the User, if the User
                violates the procedure and terms of use of the Service established by the Agreement.

              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">In case of early termination of this Agreement for any reason, the Pricing plan paid by
                the User in accordance with this Agreement is non-refundable.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">11. DISPUTE SETTLEMENT</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">All disputes between the Parties in the course of execution of the Agreement are
                settled by negotiations.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">If the Parties do not settle disputes and disagreements through negotiations, such
                disputes and disagreements are resolved by the Parties in court at the location of the Licensor.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">12. FORCE MAJEURE</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor is released from liability for full or partial non-fulfillment of
                obligations under this Agreement if such non-fulfillment is a result of force majeure, i.e., extraordinary
                and unavoidable by the Parties under the existing circumstances, including mass riots, prohibitive actions
                of the authorities, natural disasters, fires, catastrophes, including the fall of aircraft or other aircraft
                or space objects, and other force majeure circumstances, as well as: power failures; global outages in
                Russian and international Internet segments; failures of routing systems; failures in the distributed domain
                name system; failures caused by hacker and DOS attacks, as well as other illegal actions of a third
                party(ies).
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor undertakes, if it is technically possible, to notify the User by e-mail
                about the occurrence of force majeure within 7 (seven) days of its happening.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">If, because of force majeure, the Licensor is prevented from performing its obligations
                under the Agreement for 3 months or more, the Agreement is deemed terminated without any compensation for
                losses.
              </p>
            </li>
          </ul>
        </div>

        <div class="terms__block">
          <div class="terms__block__title">13. FINAL PROVISIONS</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The relations of the Parties under this Agreement are regulated by the current
                legislation of the Russian Federation.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor informs the User that according to Article 1262 of the Civil Code of the
                Russian Federation, the state registration of rights in relation to electronic computer machines is carried
                out at the request of the right holder. The state registration of rights to the Program has not been carried
                out.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Upon the User's request, the Parties may sign the Agreement in writing form and
                exchange its originals.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Licensor has the right to unilaterally make changes to the terms and conditions of
                the Agreement at any time without prior notice to the User, such changes take effect from the moment of
                posting the new version of the Agreement on the Website.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">The Parties have agreed that any documents are to be sent by the Parties to each other
                via e-mail addresses: on the part of the Licensor from the e-mail address of the <a href="https://charla-ai.com/">charla-ai.com</a> domain.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Telegram bot <a class="colored__text" href="t.me/charla_ru_bot">t.me/charla_ru_bot</a>
                and e-mail correspondence is legally valid.
              </p>
            </li>
          </ul>
          <br>
          <p class="terms__text">LCC TEAMLEAD</p><br>
          <p class="terms__text">TAXPAYER IDENTIFICATION NUMBER (INN) 2311333380</p><br>
          <p class="terms__text">TAX REGISTRATION REASON CODE (KPP) 231101001</p><br>
          <p class="terms__text">PRIMARY STATE REGISTRATION NUMBER (OGRN) 1222300022482</p><br>
          <p class="terms__text">RUSSIAN BUSINESS AND ORGANIZATION CLASSIFICATION (OKPO) 50412179</p><br>
          <p class="terms__text">Business address: Russia, 350012, Krasnodar Krai, Krasnodar, Ivan Shkabura ul, 8, korp.
            1, pom. 29</p><br>
          <p class="terms__text">Contact person: Ionov Dmitry Alexandrovich</p><br>
          <p class="terms__text">Acc.: 40702810910001059447</p><br>
          <p class="terms__text">RCBIC: 044525974</p><br>
          <p class="terms__text">Bank name: Tinkoff Bank</p><br>
          <p class="terms__text">RCBIC: 30101810145250000974</p><br>
        </div>
      </div>
      <div class="terms__container" v-if="Tr.currentLocale === 'ru'">
        <div class="terms__title">Публичная оферта на использование сервиса Charla</div>
        <div class="terms__block">
          <p class="terms__text">Редакция действует с 10 ноября 2023 г.</p><br>
          <p class="terms__text">Настоящая оферта (далее – «Оферта») адресована любому физическому или юридическому
            лицу, индивидуальному предпринимателю и является публичным предложением ООО «ТимЛид» заключить лицензионный
            договор на право использования сервиса Charla на условиях простой (неисключительной) лицензии (далее –
            «Договор»).</p><br>
          <p class="terms__text">Договор считается заключенным и приобретает силу с момента совершения Пользователем
            действий, предусмотренных настоящей Офертой, и означающих безоговорочное принятие Пользователем всех условий
            Оферты без каких-либо изъятий или ограничений на условиях присоединения.</p><br>
          <p class="terms__text">Настоящая Оферта определяет порядок использования Сервиса Charla и доступна для
            ознакомления неограниченному кругу лиц на информационных ресурсах Лицензиара
            <a href="https://charla-ai.com/" class="colored__text">https://charla-ai.com/</a></p>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</div>
          <div class="main__grid">
            <p class="main__text">Оферта</p>
            <p class="main__text">предложение Лицензиара, адресованное любому лицу, желающему заключить Договор на
              условиях, которые определены в настоящей оферте</p>
            <p class="main__text">Акцепт</p>
            <p class="main__text">полное и безоговорочное принятие Пользователем условий оферты, совершенное путем
              регистрации</p>
            <p class="main__text">Лицензиар</p>
            <p class="main__text">ООО «ТимЛид», обладающее исключительным правом на Сервис Charla</p>
            <p class="main__text">Пользователь</p>
            <p class="main__text">физическое или юридическое лицо, индивидуальный предприниматель, осуществившее Акцепт
              Оферты</p>
            <p class="main__text">Сервис Charla</p>
            <p class="main__text">программное обеспечение для ЭВМ в виде совокупности команд, предназначенных для
              обеспечения транскрибации (расшифровки аудиофайлов в текст):
              — бот в мессенджере Telegram t.me/charla_ru_bot
              — API (предоставляется Лицензиаром по прямому запросу)</p>
            <p class="main__text">Сайт</p>
            <p class="main__text">сайт Charla, расположенный в сети интернет по адресу https://charla-ai.com/, и его
              поддомены</p>
            <p class="main__text">Сервер Лицензиара</p>
            <p class="main__text">комплекс компьютеров, управляемый Лицензиаром в целях обеспечения функционирования
              Сервиса для Пользователя</p>
            <p class="main__text">Лицензия</p>
            <p class="main__text">право использования Сервиса в объеме и пределах, установленных настоящим Договором и
              Тарифом по Лицензиям, на условиях простой (неисключительной) лицензии с сохранением за Лицензиаром права
              выдачи лицензий другим лицам</p>
            <p class="main__text">Лицензионное вознаграждение</p>
            <p class="main__text">вознаграждение за предоставление Лицензии, которое Пользователь обязуется уплатить
              Лицензиару в соответствии с настоящим Договором</p>
            <p class="main__text">Тариф</p>
            <p class="main__text">размер лицензионного вознаграждения за предоставление доступа к сервису Charla,
              которое Пользователь обязуется уплатить Лицензиару, выбрав доступный Тариф на Сайте по адресу:
              https://charla-ai.com/</p>
            <p class="main__text">Период оплаты</p>
            <p class="main__text">период времени, оплаченный Пользователем согласно выбранному Тарифу за использование
              сервиса Charla</p>
          </div>
          <br>
          <p class="terms__text">Иные термины и определения, используемые в Оферте, имеют значение и подлежат толкованию
            в соответствии с положениями настоящей Оферты, а в случае отсутствия данных положений в Оферте – в
            соответствии со сложившейся практикой использования этих терминов и определений в гражданском обороте, а
            также в соответствии с законодательством Российской Федерации.</p>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">2. БЕСПЛАТНЫЙ ДОСТУП</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">С момента регистрации на сайте каждый Пользователь получает 5 дней бесплатного
                безлимитного доступа к функционалу Сервиса Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Дополнительные 5 дней бесплатного безлимита могут быть предоставлены Пользователю
                за оставленный отзыв о работе с сервисом.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар вправе устанавливать иной срок действия бесплатного доступа в
                одностороннем порядке путем размещения соответствующей информации на Сайте.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Бесплатный доступ истекает после использования предоставленных 5 дней начального
                периода, а также возможных дополнительных 5 дней, полученных за отзыв. Неиспользованный период
                бесплатного доступа не продлевается.

              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">3. ПЛАТНЫЙ ДОСТУП. ВОЗНАГРАЖДЕНИЕ</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Для использования Сервиса Charla по истечении периода бесплатного доступа
                Пользователь выбирает Тариф.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Тариф оплачивается Пользователем c использованием электронных платежных систем.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Днем оплаты Тарифа считается день поступления денежных средств на расчетный счет
                Лицензиара.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Все расчеты Сторон по настоящему Договору производятся в рублях РФ.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Все банковские либо иные комиссионные платежи, связанные с оплатой Лицензионного
                платежа, оплачиваются Пользователем.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">По истечении Периода оплаты действие Тарифа может продлеваться на аналогичный
                период при наличии на Лицевом счете Пользователя денежных средств, достаточных для оплаты Тарифа на
                новый срок, в случае если Пользователь не ограничил доступ к Сервису Charla самостоятельно.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Пользователь вправе отказаться от выбранного им Тарифа, кроме случаев смены Тарифа
                на более дорогой Тариф, в течение 7 (семи) календарных дней с даты активации Тарифа, направив обращение
                в службу технической поддержки. На основании такого обращения Лицензиар возвращает списанные денежные
                средства за ошибочно выбранный Тариф на Лицевой счет Пользователя за вычетом вознаграждения Лицензиара
                за время фактического предоставления доступа к Сервису Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Прекращение Пользователем использования оплаченного Тарифа, в том числе в связи с
                допущенным им нарушением условий Договора, не является основанием для возврата оплаченного Тарифа.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар вправе изменять Тарифы в одностороннем порядке, без предварительного
                согласования с Пользователем, стоимость уже оплаченного Тарифа остается неизменной. Об изменении Тарифов
                Лицензиар уведомляет Пользователя путем размещения информации на Сайте. Изменения вступают в силу на
                следующий календарный день после размещения информации на Сайте.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензионное вознаграждение не облагается НДС на основании подпункта 26 пункта 2
                статьи 149 Налогового кодекса РФ.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</div>
          <br>
          <p class="terms__list"><b>Пользователь имеет право:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Приобрести в порядке и на условиях, предусмотренных настоящим Договором простое
                неисключительное право на пользование Сервисом Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Осуществлять круглосуточный доступ к Сервису Charla, за исключением времени
                проведения профилактических работ в соответствии с условиями настоящего Договора, при условии
                своевременной уплаты Лицензионного платежа.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Отказаться от использования Сервиса Charla в порядке, установленном настоящим
                Договором.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Представлять Лицензиару предложения по улучшению и доработке Сервиса Charla на
                русском языке.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Изменять настройки Сервиса Charla в пределах, предоставленных Пользователю.
              </p>
            </li>
          </ul>
          <br>
          <p class="terms__list"><b>Пользователь обязан:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Использовать Сервис Charla самостоятельно и не передавать права на использование
                Сервисом Charla и копии третьим лицам.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Своевременно оплачивать лицензионное вознаграждение в размере и в сроки,
                предусмотренные выбранным Тарифом.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Обеспечить сохранность полученных от Лицензиара параметров доступа к Сервису Charla
                с целью защиты от несанкционированного доступа посторонних лиц.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Использовать Сервис Charla исключительно с соблюдением всех условий настоящего
                Договора.</p>
            </li>
          </ul>
          <br>
          <p class="terms__list"><b>Пользователю запрещается:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Использовать Сервис Charla для совершения противоправных действий, в том числе
                спам-рассылок, отправлений угроз и оскорблений, распространения ложной рекламы, призывов к насилию,
                отдельных видов информации и материалов, распространяемых посредством информационно-телекоммуникационной
                сети «Интернет», распространение которых в РФ запрещено, и других.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Получать доступ к исходному коду Сервиса Charla, изучать и редактировать его, а
                также раскрывать технологические принципы функционирования Сервиса Charla.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Копировать и воспроизводить Программу Сервиса Charla или отдельные ее элементы и
                сохранять Программу Сервиса Charla на любых носителях с целью передачи третьему лицу (третьим лицам), а
                также публиковать Программу Сервиса Charla, предоставляя третьему лицу (третьим лицам) возможность ее
                копирования.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Предоставлять Сервис Charla в аренду или в иное временное пользование третьему лицу
                (третьим лицам) с целью извлечения прибыли Пользователем и (или) третьим лицом (третьими лицами).</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Пытаться преодолеть технические ограничения, установленные в Сервисе Charla.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Размещать в Сервисе Charla и (или) с использованием Сервиса Charla распространять
                вредоносные программы для ЭВМ (вирусы).</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Передавать третьим лицам пароли и логины, используемые для доступа к Сервису
                Charla, обеспечивать их конфиденциальность.</p>
            </li>
          </ul>
          <br>
          <p class="terms__list"><b>Лицензиар имеет право:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Блокировать доступ Пользователя к Сервису Charla при нарушении Пользователем любых
                условий настоящего Договора, а также по требованию суда и (или) иных уполномоченных государственных
                органов РФ. При такой блокировке взимание Тарифа не производится.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Временно блокировать доступ Пользователя к Сервису Charla при образовании
                задолженности.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Приостанавливать доступ Пользователя к Сервису Charla в случае нарушения последним
                условий настоящего Договора.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Требовать от Пользователя дополнительную контактную информацию, а также информацию
                о сертификатах и (или) лицензиях, если есть основания считать, что Пользователем не соблюдается
                законодательство РФ или при поступлении запроса от уполномоченных правоохранительных органов, а при
                отказе Пользователя предоставить указанную информацию, Лицензиар имеет право ограничить право доступа
                Пользователя к Сервису Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Совершенствовать Сервис Charla без согласия Пользователя и внедрять различные
                обновления Сервиса Charla.
              </p>
            </li>
          </ul>
          <br>
          <p class="terms__list"><b>Лицензиар обязан:</b></p><br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Предоставить в порядке и на условиях, предусмотренных настоящим Договором
                Пользователю простое неисключительное право на использование Сервиса Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Обеспечить функционирование Сервиса Charla и допуск Пользователя к Сервису Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Сообщать Пользователю о существенных обновлениях Сервиса Charla и об изменениях в
                условиях передачи прав на использование Сервиса Charla электронной почтовой рассылкой и (или)
                публикацией на Сайте Лицензиара на русском языке.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Информировать Пользователя об изменении Тарифов посредством электронной почтовой
                рассылки и (или) публикации на Сайте Лицензиара на русском языке.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">5. ПОРЯДОК ИСПОЛЬЗОВАНИЯ СЕРВИСА CHARLA</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">При оплате соответствующего Тарифа Пользователю предоставляется право использования
                Сервиса Charla путем воспроизведения, ограниченного установкой, запуском, настройками Сервиса Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Распространение Сервиса не допускается. Под распространением Сервиса понимается, в
                частности: предоставление доступа третьим лицам к воспроизведенным в любой форме компонентам Сервиса, в
                том числе путем продажи, проката, сдачи внаем или предоставления взаймы.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Доступ к Сервису Charla ограничен сроком периода оплаты текущего Тарифа
                Пользователя.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Если Пользователь не отказался от выбранного Тарифа в течение 7 (семи) календарных
                дней с даты оплаты выбранного Тарифа, Лицензия на использование Сервиса Charla считается предоставленной
                Пользователю в полном объеме надлежащим образом.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Со дня заключения договора и на весь период срока использования Сервиса Charla
                Пользователь приобретает право на получение от Лицензиара базовой поддержки по устранению выявленных
                Пользователем ошибок в Сервисе Charla:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">если ошибка препятствует эксплуатации Сервиса Charla — в течение 3 (трех) дней
                после получения от Пользователя уведомления об ошибке;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">если ошибка не препятствует эксплуатации Сервиса Charla — в течение 20 (двадцати)
                дней после получения от Пользователя уведомления об ошибке.
              </p>
            </li>
          </ul>
          <br>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Пользователь осведомлен и согласен с тем, что Лицензиар может продлить срок
                устранения ошибки, либо убрать отдельные функции из Сервиса совсем, если установит, что устранение
                ошибок зависит от других лиц, так как Сервис Charla тесно взаимодействует с программами иных
                разработчиков, операционной системой и аппаратными ресурсами персонального компьютера Пользователя.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">6. ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">За дополнительную плату Лицензиар может оказывать Пользователю Дополнительные
                услуги:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">по обучению персонала Пользователя технологии работы в Сервисе Charla;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">по технической поддержке Программы, в объеме и по ценам, указанным в Тарифах;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">по изменению функциональности Сервиса Charla, появлению новых свойств и
                возможностей Сервиса Charla, автоматизации неавтоматизированных ранее ручных операций, иных доработок,
                выходящих за пределы адаптации Сервиса Charla (доработка Сервиса Charla).
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Услуги по обучению Пользователя работе в Сервисе Charla включает в себя обучающие
                онлайн-консультации, иные услуги, перечисленные в Тарифах на Сайте.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Техническая поддержка предусматривает технические и обучающие консультации, иные
                услуги, перечисленные в Тарифах на Сайте. Период оказания услуг по технической поддержке в рамках
                платных тарифных планов устанавливается в месяцах, исчисляется с момента оплаты счета Пользователем.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Техническая поддержка осуществляется только для одного экземпляра установленной
                Программы.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Цена на дополнительные услуги определяется в соответствии с выбранным Пользователем
                Тарифом
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Оплата счета Дополнительных услуг осуществляется Пользователем:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">безналичным переводом на расчетный счет Лицензиара на основании выставленного
                Лицензиаром счета;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">с использованием электронных платежных систем.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар вправе приостановить оказание Дополнительных услуг вследствие
                невозможности получить доступ к оборудованию Пользователя, информационным системам Пользователя и другим
                ресурсам, необходимым для надлежащего оказания услуг, до момента предоставления соответствующего
                доступа. Стоимость за оплаченный период при этом не уменьшается.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">7. ОТВЕТСТВЕННОСТЬ СТОРОН</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Стороны несут ответственность за несоблюдение условий настоящего Договора в
                соответствии с законодательством РФ.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Пользователь несет ответственность: за любые свои самостоятельные действия и
                действия третьего лица (третьих лиц) в Сервисе Charla, которым Пользователь предоставил доступ к Сервису
                Charla, в том числе по работе с настройкой, шаблонами и данными Программы; за порядок ведения своих
                операций с использованием Сервиса Charla; за сохранность параметров доступа Пользователя к Сервису
                Charla и за убытки, которые могут быть причинены вследствие несанкционированного использования его
                доступа; за размещение любых материалов, в том числе рекламы и материалов, являющихся объектом
                авторского права по законодательству РФ.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар не гарантирует возможность исправления результатов самостоятельных
                действий Пользователя и третьего лица (третьих лиц).
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар не несет ответственности перед Пользователем за любой ущерб, любую потерю
                доходов, прибыли, упущенной выручки, информации или сбережений, связанных с использованием или с
                невозможностью использования Сервиса Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар не несет ответственности за неисполнение или ненадлежащее исполнение
                обязательств по настоящему Договору, в том числе за прямые и/или косвенные убытки Пользователя, включая
                без ограничения прямые и/или косвенные убытки Пользователя в результате:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">неправомерных действий пользователей сети Интернет;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">отсутствия и/или наличия проблем при установлении интернет-соединения между
                сервером Пользователя и Сервером;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">проведения органами государственной власти оперативно-розыскных мероприятий, если
                проведение таких мероприятий не вызвано осуществлением Лицензиаром противоправной деятельности,
                подтвержденным вступившим в законную силу решением суда;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">незаконного ограничения доступа к Сайту третьими лицами.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Ответственность Лицензиара перед Пользователем ограничена реальным ущербом, но не
                более, чем стоимость оплаченного Тарифа за последний месяц использования Сервиса Charla.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар не гарантирует Пользователю при использовании Сервиса Charla пригодность
                для конкретных целей Пользователя, прибыльность в результате использования Пользователем Сервиса Charla,
                производительность, системную интеграцию, бесперебойное функционирование, отсутствие ошибок, исправление
                неполадок.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">8. УСЛОВИЯ СОБЛЮДЕНИЯ КОНФИДЕНЦИАЛЬНОСТИ</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Стороны считают конфиденциальными условия настоящего Договора, а также всю
                информацию, полученную одной Стороной от другой Стороны при его заключении и исполнении (далее —
                «Конфиденциальная информация»).</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Стороны обязуются не раскрывать, не публиковать, не разглашать, не обнародовать и
                иным способом не предоставлять Конфиденциальную информацию третьему лицу (третьим лицам) без
                предварительного письменного разрешения каждой Стороны.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Каждая из Сторон предпримет все необходимые меры для защиты Конфиденциальной
                информации с применением тех же мер, которые Сторона применяет для защиты собственной Конфиденциальной
                информации.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">9. ПЕРСОНАЛЬНЫЕ ДАННЫЕ</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Пользователь дает Лицензиару добровольное информированное согласие на обработку его
                персональных данных, включая фамилию и имя, номер телефона, адрес электронной почты в целях исполнения
                Договора. Заключая настоящий Договор, Пользователь подтверждает, что он ознакомился и согласен с
                Политикой Лицензиара в отношении обработки персональных данных, расположенной по адресу:
                https://charla-ai.com/.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Обработка персональных данных Пользователя включает их хранение, обработку,
                деперсонализацию и уничтожение.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">IP-адрес, имя домена, тип браузера и операционная система, дата и время посещения
                собирается и сохраняется Лицензиаром в деперсонализированном виде для ведения статистики
                посещаемости.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар использует персональные данные Пользователя в маркетинговых, рекламных и
                информационных целях, включая:
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">информирование о конкурсах и рекламных акциях;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">рассылку новостей и бизнес-предложений;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">персонализацию сайта на основе статистического анализа данных.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар имеет право рассылать электронные письма, SMS и другие сообщения,
                содержащие новости, рекламные акции, уведомления о событиях.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">10. ДЕЙСТВИЕ ДОГОВОРА. ДОСРОЧНОЕ РАСТОРЖЕНИЕ ДОГОВОРА</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Договор считается заключенным с любым физическим или юридическим лицом,
                индивидуальным предпринимателем с момента полного и безоговорочного акцепта таким лицом условий
                настоящего Договора. С момента полного и безоговорочного акцепта условий настоящего Договора указанное
                лицо становится Стороной настоящего Договора.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Настоящий Договор вступает в силу с момента его заключения.</p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Договор может быть расторгнут в следующих случаях:</p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">по соглашению Сторон в любое время;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">по инициативе любой из Сторон с письменным уведомлением другой Стороны за 30
                (тридцать) календарных дней до расторжения Договора;
              </p>
            </li>
            <li class="terms__list__marked">
              <p>
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="black"/>
                </svg>
              </p>
              <p class="terms__text">по инициативе Лицензиара немедленно и без письменного уведомления Пользователя,
                если Пользователь нарушает порядок и условия использования Сервиса, установленные Договором.

              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">В случае досрочного прекращения настоящего Договора по любой причине уплаченный
                Пользователем в соответствии с настоящим Договором Тариф не подлежит возврату.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">11. УРЕГУЛИРОВАНИЕ СПОРОВ</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Все споры, которые возникнут между Сторонами в ходе исполнения Договора,
                разрешаются путем переговоров.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">В случае, если Стороны не урегулировали споры и разногласия путем переговоров,
                такие споры и разногласия разрешаются Сторонами в суде по месту нахождения Лицензиара.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">12. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар освобождается от ответственности за полное или частичное неисполнение
                обязательств по настоящему Договору, если такое неисполнение явится следствием действия непреодолимой
                силы (форс-мажор), то есть чрезвычайных и непредотвратимых Сторонами при данных условиях обстоятельств,
                в том числе массовых беспорядков, запретительных действий властей, стихийных бедствий, пожаров,
                катастроф, включая падение воздушного судна или иных летательных аппаратов, либо космических объектов, и
                других обстоятельств непреодолимой силы, а также: перебоями в электропитании; глобальными перебоями в
                работе российских и международных сегментов сети интернет; сбоями систем маршрутизации; сбоями в
                распределенной системе доменных имен; сбоями, вызванными хакерскими и DOS-атаками, а также иными
                противоправными действиями третьего лица (третьих лиц).
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар обязуется, в случае наличия технической возможности, по электронной почте
                уведомить Пользователя о возникновении форс-мажорных обстоятельств в течение 7 (семи) дней с момента их
                наступления.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Если в результате наступления форс-мажорных обстоятельств Лицензиар будет лишен
                возможности выполнить свои обязательства по Договору в течение 3-х и более месяцев, то Договор считается
                расторгнутым без возмещения убытков.
              </p>
            </li>
          </ul>
        </div>
        <div class="terms__block">
          <div class="terms__block__title">13. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</div>
          <ul class="terms__list">
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Отношения Сторон по настоящему Договору регулируются действующим законодательством
                РФ.

              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар информирует Пользователя, что согласно ст. 1262 Гражданского кодекса
                Российской Федерации государственная регистрация прав в отношении программ для ЭВМ осуществляется по
                желанию правообладателя. Государственная регистрация прав на Программу не проводилась.

              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">По запросу Пользователя Стороны могут подписать Договор в письменной форме и
                обменяться его оригиналами.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Лицензиар вправе в любое время в одностороннем порядке без предварительного
                уведомления Пользователя вносить изменения в условия Договора, такие изменения вступают в силу с момента
                опубликования новой версии Договора на Сайте.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Стороны пришли к соглашению, что любые документы направляются Сторонами друг другу
                по адресам электронной почты: со стороны Лицензиара c адреса электронной почты с домена charla-ai.ru.
              </p>
            </li>
            <li>
              <p>
                <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H7.644V1.96H0V0ZM7.504 0H15.148V1.96H7.504V0Z" fill="#98DA2C"/>
                </svg>
              </p>
              <p class="terms__text">Переписка в телеграм-боте <a :href="$t('charlaTg')">t.me/charla_ru_bot</a> и по
                электронной почте имеет юридическую силу.
              </p>
            </li>
          </ul>
          <br>
          <p class="terms__text">ООО ТИМЛИД</p><br>
          <p class="terms__text">ИНН 2311333380</p><br>
          <p class="terms__text">КПП 231101001</p><br>
          <p class="terms__text">ОГРН 1222300022482</p><br>
          <p class="terms__text">ОКПО 50412179</p><br>
          <p class="terms__text">Юридический адрес 350012, Краснодарский край, г.Краснодар, ул. Им. Ивана Шкабуры, дом
            8, корпус 1, помещение 29</p><br>
          <p class="terms__text">Контактное лицо: Ионов Дмитрий Александрович</p><br>
          <p class="terms__text">Расчётный счёт: 40702810910001059447</p><br>
          <p class="terms__text">БИК: 044525974</p><br>
          <p class="terms__text">Название банка: АО ТИНЬКОФФ БАНК</p><br>
          <p class="terms__text">Корр. счёт: 30101810145250000974</p><br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Tr from "@/i18n/translation";

export default {
  name: "CharlaTermsPopup",
  methods: {
    ...mapActions(['setTermsState']),
    closeTerms() {
      const termsWrapper = document.querySelector('.terms__wrapper');
      const termsBackground = document.querySelector('.terms__background');
      const termsClose = document.querySelector('.terms__close')

      termsWrapper.classList.add('terms__wrapper__disable');
      termsBackground.classList.add('terms__background__disable');
      termsClose.classList.add('terms__close__disable')

      setTimeout(() => {
        termsWrapper.classList.remove('terms__wrapper__disable');
        termsBackground.classList.remove('terms__background__disable');
        termsClose.classList.remove('terms__close__disable')
        this.setTermsState(false)
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['getTermsState']),
    Tr() {
      return Tr
    }
  }
}
</script>

<style scoped lang="scss">
.colored__text {
  color: #B561F6;
}

.li-eng li {
  line-height: 157%
}

.main__grid {
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  border: 1px solid #98da2c;
  border-radius: 20px;
  overflow: hidden;
}

.main__text {
  border: 1px solid #98da2c;
  padding: 10px;
}

.terms {
  &__background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__close {
    position: fixed;
    background-color: white;
    top: 48px;
    left: calc(50% + 418px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: 500ms ease forwards __slideUpClose;

    &__disable {
      animation: 500ms ease forwards __slideDownClose;
    }
  }

  &__wrapper {
    padding: 64px;
    position: fixed;
    left: 50%;
    top: 24px;
    transform: translate(-50%, 0);
    max-width: 836px;
    width: 100%;
    background-color: white;
    height: calc(100vh - 48px);
    overflow: hidden scroll;
    z-index: 10000;
    border-radius: 44px;
    animation: 500ms ease forwards __slideUp;

    &__disable {
      animation: 500ms ease forwards __slideDown;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }

  &__block {
    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      padding: 0 0 32px 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    & > li.terms__list__marked {
      grid-template: 1fr / 72px 1fr;

      & > p {
        position: relative;

        & > svg {
          position: absolute;
          left: 50px;
          top: 9px;
        }
      }
    }
  }
}

.terms__text {
  //margin: 15px 0;
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }
  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }
  to {
    transform: translate(50%, 120vh);
  }
}

@media screen and (max-width: 660px) {
  .terms__wrapper {
    width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    padding: 0;
    top: 8px;
    border-radius: 26px;
    box-sizing: border-box;
  }
  .terms__container {
    gap: 24px;
    padding: 16px;
  }
  .terms__title {
    font-size: 28px;
    margin: 10px 0 0 0
  }
  .terms__close {
    background: #000;
    top: 18px;
    right: 36px;
    left: unset;
    width: 36px;
    height: 36px;
  }
  .terms__close svg {
    width: 15px;
    height: 15px;
  }
  .terms__close svg path {
    fill: #fff;
  }
  .terms__list {
    gap: 8px;
    padding-left: 20px;
  }
  .terms__text {
    margin: 5px;
    font-size: 13px;
  }
  .li-eng li {
    font-size: 13px;
  }
  .li-eng b {
    font-size: 14px;
  }
  .terms__block__title {
    font-size: 18px;
    margin: 0 0 32px 0;
    text-align: center;
    padding: 0;
  }
  .terms__list > li.terms__list__marked > p {
    margin: 0;
  }
  .main__text {
    font-size: 13px;
    padding: 5px;
    margin: 0;
  }
}

@media screen and (max-width: 660px) {
  .terms__title {
    max-width: 90%;
  }
}
</style>