<template>
  <div class="working__popup" v-show="getWorkingState">
    <div class="working__background" @click="closeWorking()"></div>
    <div class="working__close" @click="closeWorking()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
            fill="black"/>
      </svg>
    </div>
    <div class="working__wrapper">
      <div class="working__container">
        <div class="working__title">{{ $t("termsOfUse.mainTitle") }}</div>
        <div class="working__bloc">
          <p class="working__text working__text__dash">{{ $t("termsOfUse.start.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.start.text2") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.start.text3") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.start.text4") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block1.text1") }}</p>
          <br>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block1.text2") }}</p>
          <br>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1_1") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block2.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block2.text2") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block2.text3") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block2.text4") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block2.text5") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title1_2") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block3.text1") }}</p>
          <br>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block3.text2") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title2") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block4.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block4.text2") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block4.text3") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text4") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text5") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text6") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text7") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text8") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text9") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text10") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title3") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text2") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text3") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text4") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text5") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title4") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block6.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block6.text2") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text3") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text4") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text5") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text6") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text7") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block6.text8") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title5") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text2") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text3") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text4") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text5") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title6") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block8.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block8.text2") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title7") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block9.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block9.text2") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block9.text3") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title8") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text2") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text3") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text4") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text5") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text6") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text7") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text8") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block10.text9") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block10.text10") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block10.text11") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block10.text12") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text13") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title9") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text1") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text2") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text3") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text4") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text5") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text6") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text7") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text8") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text9") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text10") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text11") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text12") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text13") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text14") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text15") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text16") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text17") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text18") }}</p>
          <p class="working__text working__text__pad" v-if="Tr.currentLocale==='en'">{{ $t("termsOfUse.block11.text19") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text20") }}</p>
          <p class="working__text working__text__pad" v-if="Tr.currentLocale==='en'">{{ $t("termsOfUse.block11.text21") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text22") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text23") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title10") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block12.text1") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title11") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block13.text1") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title12") }}</div>
          <p class="working__text">{{ $t("termsOfUse.block14.text1") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__tit">{{ $t("termsOfUse.title13") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block15.text1") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block15.text2") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title14") }}</div>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block16.text1") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block16.text2") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block16.text3") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block16.text4") }}</p>
          <p class="working__text working__text__pad" v-if="Tr.currentLocale==='en'">{{ $t("termsOfUse.block16.text5") }}</p>
          <p class="working__text working__text__pad">{{ $t("termsOfUse.block16.text6") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block16.text7") }}</p>
          <p class="working__text working__text__dash">{{ $t("termsOfUse.block16.text8") }}</p>
        </div>
        <div class="working__block">
          <div class="working__block__title">{{ $t("termsOfUse.title15") }}</div>
          <p class="working__text" v-html='$t("termsOfUse.block17.text1")'></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Tr from "@/i18n/translation";

export default {
  name: "CharlaWorkingPopup",
  methods: {
    ...mapActions(['setWorkingPopup']),
    closeWorking() {
      const workingWrapper = document.querySelector('.working__wrapper');
      const workingBackground = document.querySelector('.working__background');
      const workingClose = document.querySelector('.working__close')

      workingWrapper.classList.add('working__wrapper__disable');
      workingBackground.classList.add('working__background__disable');
      workingClose.classList.add('working__close__disable');

      setTimeout(() => {
        workingWrapper.classList.remove('working__wrapper__disable');
        workingBackground.classList.remove('working__background__disable');
        workingClose.classList.remove('working__close__disable')
        this.setWorkingPopup(false)
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['getWorkingState']),
  },
  setup(){
    return{Tr}
  },
}
</script>

<style scoped lang="scss">
.main__grid {
  display: grid;
  grid-template-columns: minmax(auto, 300px) 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 20px;
}

.main__text {
  padding: 10px;
  height: 100%;
}
.main__grid > p, .main__grid > div {
  border: 1px solid #98da2c;
}
.main__grid__title {
  font-size: 20px;
  font-weight: 500;
  padding: 10px;
  justify-self: center;
  width: 100%;
  text-align: center;
}
.main__list_hollow {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 10px;
}
.main__list_hollow > li {
  position: relative;
  padding: 0 0 0 80px;
}
.main__list_hollow > li::before {
  content: "";
  border: #98da2c 1px solid;
  position: absolute;
  border-radius: 50%;
  left: 60px;
  top: 7px;
  width: 6px;
  height: 6px;
}
.working {
  &__background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__close {
    position: fixed;
    background-color: white;
    top: 48px;
    left: calc(50% + 418px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: 500ms ease forwards __slideUpClose;

    &__disable {
      animation: 500ms ease forwards __slideDownClose;
    }
  }

  &__wrapper {
    padding: 64px;
    position: fixed;
    left: 50%;
    top: 24px;
    transform: translate(-50%, 0);
    max-width: 836px;
    width: 100%;
    background-color: white;
    height: calc(100vh - 48px);
    overflow: hidden scroll;
    z-index: 10000;
    border-radius: 44px;
    animation: 500ms ease forwards __slideUp;

    &__disable {
      animation: 500ms ease forwards __slideDown;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;

    &__pad{
      padding: 0 0 16px 30px;
    }

    &__dash{
      position: relative;
      padding: 0 0 16px 30px;

      &::before{
        content: url("@/assets/img/termsDash.svg");
        position: absolute;
        left: 0;
        top: -5px;
      }
    }
  }

  &__block {
    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      padding: 0 0 32px 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    & > li.working__list__marked {
      grid-template: 1fr / 72px 1fr;

      & > p {
        position: relative;

        & > svg {
          position: absolute;
          left: 50px;
          top: 9px;
        }
      }
    }
  }
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }
  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }
  to {
    transform: translate(50%, 120vh);
  }
}

@media screen and (max-width: 660px) {
  .main__grid__title{
    font-size: 13px;
    padding: 5px;
    border: none!important;
  }
  .main__grid{
    grid-template-columns: minmax(auto, 200px) 1fr;
    border: 1px solid #98da2c;
    overflow: hidden;
  }
  .first{
    margin: -5px 0 0 0!important;
    border-top: 1px solid #98da2c;
  }
  .main__grid > p, .main__grid > div {
    border: none;
  }
  .main__grid > p {
    border-top: 1px solid #98da2c;
  }
  .working__wrapper {
    width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    padding: 0;
    top: 8px;
    border-radius: 26px;
    box-sizing: border-box;
  }
  .working__container {
    gap: 24px;
    padding: 16px;
  }
  .working__title {
    font-size: 28px;
    margin: 10px 0 0 0
  }
  .working__close {
    background: #000;
    top: 18px;
    right: 36px;
    left: unset;
    width: 36px;
    height: 36px;
  }
  .working__close svg {
    width: 15px;
    height: 15px;
  }
  .working__close svg path {
    fill: #fff;
  }
  .working__list {
    gap: 8px;
    padding-left: 20px;
  }
  .working__text {
    margin: 0;
    font-size: 13px;
  }
  .working__block__title {
    font-size: 18px;
    margin: 0 0 32px 0;
    text-align: center;
    padding: 0;
  }
  .working__list > li.working__list__marked > p {
    margin: 0;
  }
  .main__text {
    font-size: 13px;
    padding: 5px;
    margin: 0;
  }
}
</style>