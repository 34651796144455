<template>
  <div class="block" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <div class="block__title">
        <span>
          <span class="block__title__icon">
            <img src="@/assets/img/titleIcon3.svg">
          </span>
          {{ $t("CharlaCompare.title_part1") }}
        </span>
        <span>
          <b v-if="Tr.currentLocale==='en'">Charla</b> {{ $t("CharlaCompare.title_part2") }}</span>
        <b v-if="Tr.currentLocale==='es'">Charla</b>
      </div>
      <div class="compare__slogan">
        {{ $t("CharlaCompare.slogan") }}
      </div>
      <div class="compare__choose">
        <div class="compare__choose__slide"></div>
        <div
            class="compare__choose__item"
            @click="onPhoneTalkClick"
        >
          {{ $t("CharlaCompare.choose_item1") }}
        </div>
        <div
            class="compare__choose__item"

            @click="onLiteraryClick"
        >
          {{ $t("CharlaCompare.choose_item2") }}
        </div>
      </div>
      <div class="compare__wave">
        <audioWave :file="audioSrc" ref="audioWave"></audioWave>
      </div>
    </div>

    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="compare__carousel__wrapper">
        <div class="compare__carousel__container" :class="Tr.currentLocale==='es' ? 'compare__carousel__container__esp' : ''">
          <div class="compare__carousel__item compare__carousel__item__charla">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M32 32H0C17.6731 32 32 17.6729 32 0L32 32Z" fill="#98DA2C" />
            </svg>
            <div class="compare__carousel__title__wrapper">
              <div class="compare__carousel__title">Charla</div>
              <div class="compare__carousel__stars">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.54017 0.563829C6.94736 -0.187943 8.05264 -0.187943 8.45983 0.563829L10.023 3.44996C10.1801 3.73987 10.4663 3.94242 10.7978 3.99831L14.0987 4.55466C14.9585 4.69957 15.3001 5.72355 14.6919 6.33308L12.3572 8.67316C12.1226 8.90822 12.0133 9.23596 12.0612 9.56041L12.5381 12.7904C12.6623 13.6317 11.7681 14.2646 10.985 13.8895L7.97885 12.4496C7.67688 12.305 7.32312 12.305 7.02114 12.4496L4.01496 13.8895C3.23192 14.2646 2.33772 13.6317 2.46193 12.7904L2.93878 9.56041C2.98668 9.23596 2.87736 8.90822 2.64283 8.67316L0.308058 6.33308C-0.300098 5.72355 0.0414538 4.69957 0.901263 4.55466L4.20216 3.99831C4.53373 3.94242 4.81993 3.73987 4.97696 3.44996L6.54017 0.563829Z" fill="white" />
                </svg>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.54017 0.563829C6.94736 -0.187943 8.05264 -0.187943 8.45983 0.563829L10.023 3.44996C10.1801 3.73987 10.4663 3.94242 10.7978 3.99831L14.0987 4.55466C14.9585 4.69957 15.3001 5.72355 14.6919 6.33308L12.3572 8.67316C12.1226 8.90822 12.0133 9.23596 12.0612 9.56041L12.5381 12.7904C12.6623 13.6317 11.7681 14.2646 10.985 13.8895L7.97885 12.4496C7.67688 12.305 7.32312 12.305 7.02114 12.4496L4.01496 13.8895C3.23192 14.2646 2.33772 13.6317 2.46193 12.7904L2.93878 9.56041C2.98668 9.23596 2.87736 8.90822 2.64283 8.67316L0.308058 6.33308C-0.300098 5.72355 0.0414538 4.69957 0.901263 4.55466L4.20216 3.99831C4.53373 3.94242 4.81993 3.73987 4.97696 3.44996L6.54017 0.563829Z" fill="white" />
                </svg>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.54017 0.563829C6.94736 -0.187943 8.05264 -0.187943 8.45983 0.563829L10.023 3.44996C10.1801 3.73987 10.4663 3.94242 10.7978 3.99831L14.0987 4.55466C14.9585 4.69957 15.3001 5.72355 14.6919 6.33308L12.3572 8.67316C12.1226 8.90822 12.0133 9.23596 12.0612 9.56041L12.5381 12.7904C12.6623 13.6317 11.7681 14.2646 10.985 13.8895L7.97885 12.4496C7.67688 12.305 7.32312 12.305 7.02114 12.4496L4.01496 13.8895C3.23192 14.2646 2.33772 13.6317 2.46193 12.7904L2.93878 9.56041C2.98668 9.23596 2.87736 8.90822 2.64283 8.67316L0.308058 6.33308C-0.300098 5.72355 0.0414538 4.69957 0.901263 4.55466L4.20216 3.99831C4.53373 3.94242 4.81993 3.73987 4.97696 3.44996L6.54017 0.563829Z" fill="white" />
                </svg>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.54017 0.563829C6.94736 -0.187943 8.05264 -0.187943 8.45983 0.563829L10.023 3.44996C10.1801 3.73987 10.4663 3.94242 10.7978 3.99831L14.0987 4.55466C14.9585 4.69957 15.3001 5.72355 14.6919 6.33308L12.3572 8.67316C12.1226 8.90822 12.0133 9.23596 12.0612 9.56041L12.5381 12.7904C12.6623 13.6317 11.7681 14.2646 10.985 13.8895L7.97885 12.4496C7.67688 12.305 7.32312 12.305 7.02114 12.4496L4.01496 13.8895C3.23192 14.2646 2.33772 13.6317 2.46193 12.7904L2.93878 9.56041C2.98668 9.23596 2.87736 8.90822 2.64283 8.67316L0.308058 6.33308C-0.300098 5.72355 0.0414538 4.69957 0.901263 4.55466L4.20216 3.99831C4.53373 3.94242 4.81993 3.73987 4.97696 3.44996L6.54017 0.563829Z" fill="white" />
                </svg>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.54017 0.563829C6.94736 -0.187943 8.05264 -0.187943 8.45983 0.563829L10.023 3.44996C10.1801 3.73987 10.4663 3.94242 10.7978 3.99831L14.0987 4.55466C14.9585 4.69957 15.3001 5.72355 14.6919 6.33308L12.3572 8.67316C12.1226 8.90822 12.0133 9.23596 12.0612 9.56041L12.5381 12.7904C12.6623 13.6317 11.7681 14.2646 10.985 13.8895L7.97885 12.4496C7.67688 12.305 7.32312 12.305 7.02114 12.4496L4.01496 13.8895C3.23192 14.2646 2.33772 13.6317 2.46193 12.7904L2.93878 9.56041C2.98668 9.23596 2.87736 8.90822 2.64283 8.67316L0.308058 6.33308C-0.300098 5.72355 0.0414538 4.69957 0.901263 4.55466L4.20216 3.99831C4.53373 3.94242 4.81993 3.73987 4.97696 3.44996L6.54017 0.563829Z" fill="white" />
                </svg>
              </div>
            </div>
            <p class="compare__carousel__text" id='charlaResultDesk'
               v-html='itemsComp[0].response[0]'>
            </p>
            <div class="compare__carousel__text__wrapper">
              <div class="compare__carousel__text__container">
                <p class="compare__carousel__text" v-html='itemsComp[0].response[1]'>
                </p>
              </div>
            </div>
            <button v-if="Tr.currentLocale==='en'" class="compare__carousel__button" @click="openAllCards()">{{showButton}}</button>
          </div>
          <div class="compare__carousel" data-translate-position="0">
            <div class="compare__carousel__item">
              <div class="compare__carousel__title__wrapper">
                <div class="compare__carousel__title">{{ $t("CharlaCompare.slider.appResponse.title") }}</div>
              </div>
              <p class="compare__carousel__text" id='appResultDesk'
                 v-html='itemsComp[1].response[0]'>
              </p>
              <div class="compare__carousel__text__wrapper">
                <div class="compare__carousel__text__container">
                  <p class="compare__carousel__text" v-html='itemsComp[1].response[1]'>
                  </p>
                </div>
              </div>
              <button v-if="Tr.currentLocale==='en'" class="compare__carousel__button" @click="openAllCards()">{{showButton}}</button>
            </div>
            <div class="compare__carousel__item compare__carousel__item_small">
              <div class="compare__carousel__title__wrapper">
                <div class="compare__carousel__title">{{ $t("CharlaCompare.slider.messengerResponse.title") }}</div>
              </div>
              <p class="compare__carousel__text" id='appResultDesk'
                 v-html='itemsComp[2].response[0]'>
              </p>
              <div class="compare__carousel__text__wrapper">
                <div class="compare__carousel__text__container">
                  <p class="compare__carousel__text" v-html='itemsComp[2].response[1]'>
                  </p>
                </div>
              </div>
              <button v-if="Tr.currentLocale==='en'" class="compare__carousel__button" @click="openAllCards()">
                {{showButton}}</button>
            </div>
            <div class="compare__carousel__item compare__carousel__item_small">
              <div class="compare__carousel__title__wrapper">
                <div class="compare__carousel__title">{{ $t("CharlaCompare.slider.searchResponse.title") }}</div>
              </div>
              <p class="compare__carousel__text" id='appResultDesk'
                 v-html='itemsComp[3].response[0]'>
              </p>
              <div class="compare__carousel__text__wrapper">
                <div class="compare__carousel__text__container">
                  <p class="compare__carousel__text" v-html='itemsComp[3].response[1]'>
                  </p>
                </div>
              </div>
              <button v-if="Tr.currentLocale==='en'" class="compare__carousel__button" @click="openAllCards()">{{showButton}}</button>
            </div>
          </div>
          <div class="carousel__button__wrapper">
            <button :class="this.translatePosition > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'" class="carousel__button carousel__button__back" @click="scrollCarousel">
              <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
              </svg>
            </button>
            <button :class="this.translatePosition === 2 ? 'carousel__button_disabled' : 'carousel__button_active'" class="carousel__button" @click="scrollCarousel('left')">
              <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tr from '@/i18n/translation';
import audioWave from "@/components/audioWave.vue";
export default {
  name: "CharlaCompare",
  components:{
    audioWave
  },
  data(){
    return{
      waveDesk: null,
      translatePosition: 0,
      activeButton: 'phoneTalk',
      audioMode: 'operator',
      showButton: 'Развернуть'
    }
  },
  setup(){
    return Tr
  },
  computed:{
    // itemsComp(){
    //   if (this.audioMode === 'operator'){
    //     return [
    //       {response: this.$t("CharlaCompare.slider.charlaResponse.phoneTalk"),},
    //       {response: this.$t("CharlaCompare.slider.appResponse.phoneTalk"),},
    //       {response: this.$t("CharlaCompare.slider.messengerResponse.phoneTalk"),},
    //       {response: this.$t("CharlaCompare.slider.searchResponse.phoneTalk"),},
    //     ]
    //   }else{
    //     return [
    //       {response: this.$t("CharlaCompare.slider.charlaResponse.literary"),},
    //       {response: this.$t("CharlaCompare.slider.appResponse.literary"),},
    //       {response: this.$t("CharlaCompare.slider.messengerResponse.literary"),},
    //       {response: this.$t("CharlaCompare.slider.searchResponse.literary"),},
    //     ]
    //   }
    // },
    itemsComp(){
      if (this.audioMode === 'operator'){
        return [
          {response: [this.$t("CharlaCompare.slider.charlaResponse.phoneTalk.part1"), this.$t("CharlaCompare.slider.charlaResponse.phoneTalk.part2")]},
          {response: [this.$t("CharlaCompare.slider.appResponse.phoneTalk.part1"), this.$t("CharlaCompare.slider.appResponse.phoneTalk.part2")]},
          {response: [this.$t("CharlaCompare.slider.messengerResponse.phoneTalk.part1"), this.$t("CharlaCompare.slider.messengerResponse.phoneTalk.part2")]},
          {response: [this.$t("CharlaCompare.slider.searchResponse.phoneTalk.part1"), this.$t("CharlaCompare.slider.searchResponse.phoneTalk.part2")]},
        ]
      }else{
        return [
          {response: [this.$t("CharlaCompare.slider.charlaResponse.literary.part1"), this.$t("CharlaCompare.slider.charlaResponse.literary.part2")]},
          {response: [this.$t("CharlaCompare.slider.appResponse.literary.part1"), this.$t("CharlaCompare.slider.appResponse.literary.part2")]},
          {response: [this.$t("CharlaCompare.slider.messengerResponse.literary.part1"), this.$t("CharlaCompare.slider.messengerResponse.literary.part2")]},
          {response: [this.$t("CharlaCompare.slider.searchResponse.literary.part1"), this.$t("CharlaCompare.slider.searchResponse.literary.part2")]},
        ]
      }
    },
    Tr() {
      return Tr
    },
    audioSrc(){
      if (Tr.currentLocale === 'en'){
        if (this.audioMode === 'operator'){
          return '/audio/operator_en.mp3';
        }else{
          return '/audio/literary_en.mp3';
        }
      }
      else if (Tr.currentLocale === 'es'){
        if (this.audioMode === 'operator'){
          return '/audio/operator_es.mp3';
        }else{
          return '/audio/literary_es.mp3';
        }
      }
      return '/audio/operator_en.mp3'
    }
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      this.stopAudio();
      this.showButton === this.$t("CharlaCompare.expandButton") ? this.showButton = this.$t("CharlaCompare.collapseButton") : this.showButton = this.$t("CharlaCompare.expandButton")
    },
  },
  methods: {
    openAllCards(){
      this.showButton === this.$t("CharlaCompare.expandButton") ? this.showButton = this.$t("CharlaCompare.collapseButton") : this.showButton = this.$t("CharlaCompare.expandButton")
      const cardWrapper = [...document.querySelectorAll('.compare__carousel__text__wrapper')];
      const cardContainer = [...document.querySelectorAll('.compare__carousel__item')];
      cardWrapper.forEach((e,i) => {
        cardContainer[i].classList.toggle('compare__carousel__item_opened')
        e.classList.toggle('compare__carousel__text__wrapper_active')
      })
    },
    scrollCarousel(direction='right'){
      const carousel = document.querySelector('.compare__carousel')

      let carouselItems = [...document.querySelectorAll('.compare__carousel__item')]
      carouselItems.shift()

      if (direction==="left"){
        this.translatePosition += 1
        carouselItems.forEach(e => {
          e.style.transform = `translate(calc(-${this.translatePosition * 100}% - ${16 * this.translatePosition}px), 0)`
        })
      }else {
        this.translatePosition -= 1
        carouselItems.forEach(e => {
          e.style.transform = `translate(calc(-${this.translatePosition * 100}% - ${16 * this.translatePosition}px), 0)`
        })
      }

      carousel.dataset.translatePosition = this.translatePosition

    },
    stopAudio() {
      this.$refs.audioWave.resetAudio();
    },
    onPhoneTalkClick() {
      const splideContainer= document.querySelector('.splide')
      const compareCarousel = document.querySelector('.compare__carousel');
      const compareCarouselItems = [...document.querySelectorAll('.compare__carousel__item')]
      compareCarousel.dataset.translatePosition = '0'
      splideContainer.dataset.switch = 'true'
      const switchButtons = [...document.querySelectorAll('.compare__choose__item')];
      switchButtons.forEach(e => {
        e.style.pointerEvents = 'none'
      })

      setTimeout(() => {
        switchButtons.forEach(e => {
          e.style.pointerEvents = 'all'
        })
      }, 600)


      setTimeout(() => {
        splideContainer.dataset.switch = 'false'
      }, 700)

      compareCarouselItems.forEach((e) => {
        e.style.transform = 'translate(0,0)'
      })

      const slideChoose = document.querySelector('.compare__choose__slide');
      slideChoose.classList.remove('compare__choose__slide_right')
      this.stopAudio();
      this.activeButton = 'phoneTalk';
      this.audioMode = 'operator';
      document.querySelector('.compare__carousel__container').classList.remove('phoneTalk-slides');
      document.querySelector('.compare__carousel__container').classList.add('literary-slides');
    },
    onLiteraryClick() {
      const splideContainer= document.querySelector('.splide')
      const compareCarousel = document.querySelector('.compare__carousel');
      const compareCarouselItems = [...document.querySelectorAll('.compare__carousel__item')]
      compareCarousel.dataset.translatePosition = '0'
      splideContainer.dataset.switch = 'true'

      const switchButtons = [...document.querySelectorAll('.compare__choose__item')];
      switchButtons.forEach(e => {
        e.style.pointerEvents = 'none'
      })

      setTimeout(() => {
        switchButtons.forEach(e => {
          e.style.pointerEvents = 'all'
        })
      }, 600)

      setTimeout(() => {
        splideContainer.dataset.switch = 'false'
      }, 700)


      compareCarouselItems.forEach((e) => {
        e.style.transform = 'translate(0,0)'
      })


      const slideChoose = document.querySelector('.compare__choose__slide');
      slideChoose.classList.add('compare__choose__slide_right')
      this.stopAudio();
      this.activeButton = 'literary';
      this.audioMode = 'literary';
      document.querySelector('.compare__carousel__container').classList.remove('literary-slides')
      document.querySelector('.compare__carousel__container').classList.add('phoneTalk-slides');
    },
  },
  mounted() {
    this.showButton = this.$t("CharlaCompare.expandButton")

    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
        } else {
          splideContainer.dataset.stopScroll = 'true'
        }
      })
    }, {
      threshold: 1
    });

    let carouselItem = [...document.querySelectorAll('.compare__carousel__item')];
    carouselItem = carouselItem[carouselItem.length - 1]
    observer.observe(carouselItem)

    const carousel = document.querySelector('.compare__carousel')
    this.translatePosition = +carousel.dataset.translatePosition

    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.translatePosition = +carousel.dataset.translatePosition
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>

<style scoped lang="scss">
.block__right{
  z-index: 100;
}
.block__title__icon{
  background-color: var(--purple);
}

.compare__slogan{
  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
  color: #000;
  max-width: 307px;
}

.compare__choose{
  max-width: 448px;
  border: 1px solid var(--green);
  border-radius: 24px;
  padding: 4px;
  display: grid;
  grid-template: 1fr / repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  position: relative;

  &__slide{
    position: absolute;
    left: 4px;
    top: 4px;
    background-color: var(--green);
    width: calc(50% - 8px);
    height: calc(100% - 8px);
    border-radius: 24px;
    z-index: 1;
    transition: 300ms all ease;

    &_right{
      left: calc(100% - 4px);
      transform: translate(-100%, 0);
    }
  }

  &__item{
    position: relative;
    z-index: 2;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #000;
    width: 100%;
    cursor: pointer;
    padding: 8px 0;

    &_active{
      //background-color: var(--green);
      //border-radius: 24px;
      //padding: 7px 0;
    }
  }
}

.compare__wave{
  max-width: 448px;
  background-color: var(--purple);
  border-radius: 32px;
  padding: 8px 16px 8px 8px;
  display: flex;
  gap: 8px;
}

.block__right{
  position: relative;
}

.compare__carousel{
  display: flex;
  overflow: hidden;
  padding: 0 0 0 16px;
  gap: 16px;

  &__button{
    justify-self: flex-start;
    width: fit-content;
    background-color: transparent;
    font-size: 13px;
    margin-top: 5px;
    display: grid;
    align-items: flex-end;
    color: var(--purple);
  }

  &__stars{
    display: flex;
    gap: 2px;
  }

  &__item{
    width: 360px;
    max-height: 360px;
    max-width: 430px;
    //overflow-y: hidden;
    background-color: white;
    padding: 32px;
    border-radius: 32px;
    transition: 300ms ease all;
    display: grid;
    grid-template: repeat(3, auto) 1fr / 1fr;
    position: relative;
    z-index: 0;

    &_opened{
      max-height: 624px;
    }


    &_small{
      //row-gap: 32px;
      & .compare__carousel__text{
        align-self: flex-start;
      }
    }

    &__charla{
      background-color: var(--green);
      border-radius: 32px 32px 32px 0;
      position: relative;
      z-index: 1;

      & .compare__carousel__button{
        color: white;
      }

      &  .compare__carousel__title{
        color: white;

      }

      & > svg{
        position: absolute;
        bottom: 0;
        left: -32px;
      }
    }
  }

  &__title{
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    color: black;
    &__wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }


  }

  &__text{
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #000;
    margin-top: 32px;

    &__wrapper{
      display: grid;
      grid-template-rows: 0fr;
      transition: 300ms ease grid-template-rows;

      &  .compare__carousel__text{
        margin: 0;
      }

      &_active{
        grid-template-rows: 1fr;
      }
    }

    & > span{
      color: red;
    }

    &__container{
      overflow: hidden;
    }
  }

  &__wrapper{
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &__container{
    display: flex;

    &__esp .compare__carousel__item{
      max-height: unset;
    }
  }
}

.colored__text{
  color: red;
}

.carousel__button__back{
  background-color: black;
}
.compare__carousel__item, .compare__carousel__container{
  transition: .3s ease-in-out;
}
@keyframes fade {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0)
  }
  to {
    opacity: 1;
    transform: translateZ(0)
  }
}
@keyframes fade2 {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0)
  }
  to {
    opacity: 1;
    transform: translateZ(0)
  }
}
.phoneTalk-slides .compare__carousel__item{
  animation: 1s fade2
}
.literary-slides .compare__carousel__item{
  animation: 1s fade
}


@media screen and (max-width: 1600px) {
  .compare__wave{
    max-width: 400px;

    & > img {
      max-width: 320px;
    }
  }

  .compare__choose{
    max-width: 400px;
  }

  .compare__carousel__wrapper{
    left: -80px;
  }
}



@media screen and (max-width: 1340px) {
  .compare__carousel__wrapper{
    left: -100px;
  }
}

@media screen and (max-width: 1300px) {
  .compare__carousel__wrapper{
    left: -120px;
  }
}
@media screen and (max-width: 1100px){
  .compare__carousel__wrapper {
    left: -72px ;
  }
  .compare__carousel__item {
    width: 260px ;
  }
  .compare__carousel__title {
    font-size: 14px;
  }
}
</style>