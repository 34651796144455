<template>
    <div class="cookie__notification" v-show="getNotificationState">
        <div class="cookie__notification__wrapper">
            <p class="cookie__notification__text">
                {{ $t("cookieNotification.cookieText.text1") }}
                <span class='cookie__notification__link'>cookies</span>
                {{ $t("cookieNotification.cookieText.text2") }}
            </p>
        </div>
        <button @click="closeCookie" class="cookie__notification__button">{{ $t("cookieNotification.cookieButton")
            }}</button>
    </div>
</template>

<script>
import Tr from '@/i18n/translation';
import { mapActions, mapGetters } from "vuex";

export default {
    methods: {
        ...mapActions(['setCookiePopup', 'setCookieNotification']),
        closeCookie() {
            const cookieNotification = document.querySelector('.cookie__notification')
            cookieNotification.classList.add('cookie__notification__disable')
            setTimeout(() => {
                this.setCookieNotification(false)
            }, 900)

            localStorage.setItem('cookie', true);
        }
    },
    computed: {
        ...mapGetters(['getCookiePopup', 'getNotificationState']),
        Tr() {
            return Tr
        }
    },
    mounted() {
        const cookieLink = document.querySelector('.cookie__notification__link');
        cookieLink.onclick = () => {
            this.setCookiePopup(true);
        }

        if (localStorage.getItem('cookie') === null) {
            setTimeout(() => {
                this.setCookieNotification(true);
            }, 2000)
        }
    }
}
</script>

<style lang="scss">
.cookie__notification {
    position: fixed;
    right: 15px;
    bottom: 24px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.5);
    background: #000;
    max-width: 460px;
    z-index: 999;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    animation: 500ms ease __slideRight forwards;

    &__disable {
        animation: 500ms ease forwards __slideLeft;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: #fff;
    }

    &__link {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }

    &__text {
        color: white;
        width: fit-content;
    }

    &__button {
        padding: 10px 20px;
        background: #fff;
        border-radius: 77px;
        font-weight: 600;
        font-size: 12px;
        line-height: 183%;
        text-align: center;
        color: #000;
        width: fit-content;
        min-width: 100px;
    }
}

@keyframes __slideRight {
    from {
        right: -50vw;
    }

    to {
        right: 15px;
    }
}

@keyframes __slideLeft {
    from {
        right: 15px;
    }

    to {
        right: -50vw;
    }
}

@media screen and (max-width: 660px) {
    .cookie__notification__wrapper {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: #f8f8f8;
    }

    .cookie__notification__button {
        font-weight: 600;
        font-size: 12px;
        line-height: 183%;
        text-align: center;
        color: #000;
        width: 100%;
    }

    .cookie__notification {
        right: 50%;
        width: 100%;
        max-width: 300px;
        bottom: 12px;
        transform: translate(50%, 0);
    }

    @keyframes __slideRight {
        from {
            right: -50vw;
        }

        to {
            right: 50%;
        }
    }

    @keyframes __slideLeft {
        from {
            right: 50%;
        }

        to {
            right: -50vw;
        }
    }
}
</style>