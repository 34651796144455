<template>
  <aside class="aside">
    <div class="aside__menu" :class="{'aside__menu__active' : getCurrentPos >= 1}" @click="this.getNewMenuState('opened');">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="14" y="23" width="2" height="2" fill="white"/>
        <rect x="20" y="22" width="2" height="4" fill="white"/>
        <rect x="26" y="20" width="2" height="8" fill="white"/>
        <rect x="32" y="23" width="2" height="2" fill="white"/>
      </svg>
      <div class="vertical-text">{{ $t("aside.menu") }}</div>
    </div>
    <div class="aside__lang" :class="[getCurrentPos < 1 ? 'aside__lang__active' : 'aside__lang__disable']">
      <button v-for="(lang, i) in languages" :key="i" class="aside__lang__button" @click="this.activeLang = i; switchLanguage(languages[i])" :class="{'aside__lang__button_active' : activeLang === i}">{{lang}}</button>
    </div>
    <div class="aside__bullets">
      <ul>
        <li class="aside__bullets__item" v-for="(n, index) in 10"
            :class="{'aside__bullets__item_active': index === getCurrentPos}" @click="moveToNewSlide();">
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import Tr from "@/i18n/translation";
import {useRoute, useRouter} from "vue-router";

export default {
  setup(){
    const route = useRoute();
    const router = useRouter()
    const switchLanguage = async (locale) => {
      await Tr.switchLanguage(locale);
      try {
        await router.replace({params:{locale:locale}})
      }catch (e){
        console.error(e)
        router.push('/')
      }
    }

    return {
      route, switchLanguage, Tr
    };
  },
  name: "CharlaAside",
  data() {
    return {
      languages: ["en", "es"],
      activeLang: 0,
    }
  },
  computed: {
    ...mapGetters(['getCurrentPos', 'getCurrentMenuState']),
    Tr() {
      return Tr
    },
  },
  methods: {
    ...mapActions(['getNewPosition', 'getNewMenuState']),
    moveToNewSlide() {
      const splideContainer = document.querySelector('.splide')
      splideContainer.dataset.stopScroll = 'false'
    }
  },
  mounted() {
    this.getNewMenuState()
    this.activeLang = this.languages.indexOf(Tr.currentLocale);
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      this.activeLang = this.languages.indexOf(Tr.currentLocale);
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/mixins";

.aside__menu:hover {
  background-color: #B561F6;
  height: 176px;
}

.aside__menu:hover .vertical-text {
  display: block;
}

.aside {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: fixed;
  height: 100vh;
  z-index: 10;
  padding: 46px 40px 40px;

  &__menu {
    max-width: 48px;
    display: flex;
    justify-content: center;
    background-color: black;
    border-radius: 24px;
    height: 112px;
    cursor: pointer;
    transition: .3s ease;

    &__active{
      height: 176px;
    }

    &__active .vertical-text{
      display: block;
    }
  }


  &__lang {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    &__button {
      background-color: white;
      cursor: pointer;
      font-size: 12px;
      text-transform: capitalize;
      padding: 7px 0;

      &_active {
        border: 1px solid rgb(0, 0, 0);
        border-radius: 16px;
      }
    }
  }

  &__bullets {
    padding: 0 0 46px;

    & > ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }

    &__item {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transition: 300ms ease all;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        border: 1px solid black;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 50%;
        opacity: 0;
        transition: 300ms ease opacity;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: black;
        border: 0;
      }

      &:hover {
        border: 1px solid var(--purple);
        opacity: 1;
      }

      &_active {

        &:hover {
          border: 0 solid var(--purple);
          opacity: 1;
        }

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.aside__lang__active {
  animation: __fadeIn 300ms ease forwards;
}

.aside__lang__disable {
  animation: __fadeOut 300ms ease forwards;
}

@keyframes __fadeIn {
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from{
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.aside__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.vertical-text {
  display: none;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  color: #fff;
  padding-top: 24px;
  transition: .3s ease;
}
@media screen and (max-width: 1600px) {
  .aside__lang__button {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .aside {
    display: none;
  }
}
</style>