<template>
    <CharlaFormPopup></CharlaFormPopup>
    <div class="subPage-background">
        <div class="subPage-wrapper">
            <subPageHeader></subPageHeader>
            <div class="page-title">
                <h1 :class="{ ruh1: Tr.currentLocale === 'ru' }">{{ $t("cookiePopup.mainTitle") }}</h1>
            </div>
            <div class="cookie__wrapper">
                <div class="cookie__container">
                    <div class="cookie__block">
                        <h2 class="cookie__title_small">{{ $t("cookiePopup.title1") }}</h2>
                        <p class="cookie__text">{{ $t("cookiePopup.block1.text1") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block1.text2") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block1.text3") }}</p>
                    </div>
                    <div class="cookie__block">
                        <h2 class="cookie__title_small">{{ $t("cookiePopup.title2") }}</h2>
                        <p class="cookie__text">{{ $t("cookiePopup.block2.text1") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block2.text2") }}</p>
                    </div>
                    <div class="cookie__block">
                        <h2 class="cookie__title_small">{{ $t("cookiePopup.title3") }}</h2>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text1") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text2") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text3") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text4") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text5") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text6") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text7") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text8") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block3.text9") }}</p>
                    </div>
                    <div class="cookie__block">
                        <h2 class="cookie__title_small">{{ $t("cookiePopup.title4") }}</h2>
                        <p class="cookie__text">{{ $t("cookiePopup.block4.text1") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block4.text2") }}</p>
                        <p class="cookie__text">{{ $t("cookiePopup.block4.text3") }}</p>
                    </div>
                    <div class="cookie__block">
                        <h2 class="cookie__title_small">{{ $t("cookiePopup.title5") }}</h2>
                        <p class="cookie__text">{{ $t("cookiePopup.block5.text1") }}</p>
                    </div>
                    <div class="cookie__block">
                        <h2 class="cookie__title_small">{{ $t("cookiePopup.title6") }}</h2>
                        <p class="cookie__text">{{ $t("cookiePopup.block6.text1") }}</p>
                    </div>
                    <div class="cookie__block">
                        <h2 class="cookie__title_small">{{ $t("cookiePopup.title7") }}</h2>
                        <p class="cookie__text" v-html='$t("cookiePopup.block7.text1")'></p>
                    </div>
                </div>
            </div>
            <subPageFooter></subPageFooter>
        </div>
    </div>
</template>

<script>
import Tr from "@/i18n/translation";
import subPageFooter from "@/components/subPages/subPageFooter.vue";
import subPageHeader from "@/components/subPages/subPageHeader.vue";
import CharlaFormPopup from "@/components/CharlaFormPopup.vue";

export default {
    name: "privacyPolicy",
    components: { CharlaFormPopup, subPageHeader, subPageFooter },
    computed: {
        Tr() {
            return Tr
        }
    },

}
</script>

<style scoped lang="sass">
.subPage-background
  background: #F6F5F8
  height: 100%
.subPage-wrapper
  max-width: 1112px
  margin: auto
  padding: 64px 0

.faq-wrapper
  margin: 64px auto

.page-title
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 64px

h1
  font-size: 56px
  font-weight: 700
  margin: 0

  span
    color: #98DA2C

.title-btn
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  max-width: 240px
  width: 100%
  height: 48px
  border-radius: 24px
  background: #000
  color: #fff
  transition: .3s ease
  cursor: pointer
  border: 1px solid #000

  &:hover
    background: #fff
    color: #000

    svg path
      transition: .3s ease
      fill: #000

p
  font-size: 16px
  line-height: 164%

  &:last-child
    margin-bottom: 0

ul
  padding-left: 30px

  li
    font-size: 16px
    margin-bottom: 12px
    line-height: 164%
    list-style-type: disc

h2
  font-size: 22px
  margin: 20px 0 10px 0

h3
  font-size: 24px
  font-weight: 400
  margin: 0

@media screen and (max-width: 1200px)
  .subPage-wrapper
    margin: 0 40px

@media screen and (max-width: 970px)
  .reviews-grid
    grid-template-columns: 1fr 1fr
  h1
    font-size: 32px

@media screen and (max-width: 660px)
  .page-title
    display: flex
    flex-direction: column
    gap: 22px
  .subPage-wrapper
    margin: 0 20px
    padding: 24px 0
  h1
    font-size: 32px
    font-weight: 400
  h2
    font-size: 24px
  h3
    font-size: 18px

  p
    font-size: 13px
    line-height: 154%
  ul li
    font-size: 13px
    line-height: 154%
  .title-btn
    font-size: 14px
    max-width: 200px


</style>


<style lang="scss" scoped>
.cookie {
    &__block {
        display: flex;
        flex-direction: column;

        & .cookie__text:last-of-type {
            padding: 0;
        }
    }


    &__wrapper {
        width: 100%;
        z-index: 10000;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }

    &__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 120%;
        color: #000;
        margin: 0;

        &_small {
            font-weight: 600;
            font-size: 22px;
            margin: 0;
            line-height: 127%;
            color: #000;
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 0 0 32px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: #000;
        padding: 0 0 16px;
    }
}
</style>