export default {
    actions: {
        setLangState(ctx, currentState = false) {
            ctx.commit('updateLangState', currentState)
        },
        setPrivacyState(ctx, currentState = false) {
            ctx.commit('updatePrivacyState', currentState)
        },
        setTermsState(ctx, currentState = false) {
            ctx.commit('updateTermsState', currentState)
        },
        setAboutState(ctx, currentState = false) {
            ctx.commit('updateAboutState', currentState)
        },
        setFormState(ctx, currentState = false) {
            ctx.commit('updateFormState', currentState)
        },
        setReadPrivacy(ctx, currentState = false) {
            ctx.commit('updateReadPrivacy', currentState)
        },
        setPhoneMask(ctx, currentMask = '') {
            ctx.commit('updatePhoneMask', currentMask)
        },
        setWorkingPopup(ctx, currenWorkingState) {
            ctx.commit('updateWorkingState', currenWorkingState)
        },
        setFaqState(ctx, currentState) {
            ctx.commit('updateFaqState', currentState)
        },
        setFaqContent(ctx, currentContent) {
            ctx.commit('updateFaqContent', currentContent)
        },
        setCookiePopup(ctx, currentState) {
            ctx.commit('updateCookiePopup', currentState)
        },
        setCookieNotification(ctx, currentContent) {
            ctx.commit('updateCookieNotification', currentContent)
        }
    },
    mutations: {
        updateLangState(state, newState) {
            state.languageState = newState
        },
        updatePrivacyState(state, newState) {
            state.privacyState = newState
        },
        updateTermsState(state, newState) {
            state.termsState = newState
        },
        updateAboutState(state, newState) {
            state.aboutState = newState
        },
        updateFormState(state, newState) {
            state.formState = newState
        },
        updateReadPrivacy(state, newState) {
            state.readPrivacy = newState
        },
        updatePhoneMask(state, newMask) {
            state.phoneMask = newMask
        },
        updateWorkingState(state, newState) {
            state.workingPopup = newState
        },
        updateFaqState(state, newState) {
            state.faqPopup = newState
        },
        updateFaqContent(state, newState) {
            state.faqContent = newState
        },
        updateCookiePopup(state, newState) {
            state.cookiePopup = newState
        },
        updateCookieNotification(state, newState) {
            state.notificationState = newState
        }
    },
    state: {
        languageState: false,
        privacyState: false,
        termsState: false,
        aboutState: false,
        formState: false,
        readPrivacy: false,
        phoneMask: '',
        workingPopup: false,
        faqPopup: false,
        faqContent: 'faqPage.faq.qa1',
        cookiePopup: false,
        notificationState: false,
    },
    getters: {
        getLangState(state) {
            return state.languageState
        },
        getPrivacyState(state) {
            return state.privacyState
        },
        getTermsState(state) {
            return state.termsState
        },
        getAboutState(state) {
            return state.aboutState
        },
        getFormState(state) {
            return state.formState
        },
        getReadPrivacy(state) {
            return state.readPrivacy
        },
        getPhoneMask(state) {
            return state.phoneMask
        },
        getWorkingState(state) {
            return state.workingPopup
        },
        getFaqState(state) {
            return state.faqPopup
        },
        getFaqContent(state) {
            return state.faqContent
        },
        getCookiePopup(state) {
            return state.cookiePopup
        },
        getNotificationState(state) {
            return state.notificationState
        }
    }
}