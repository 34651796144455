<template>
  <div class="notFound-wrapper">
    <div class="notFound-title">
      4
      <img src="@/assets/img/charla-wave.svg" alt="404">
      4
    </div>
    <div class="notFound-subtitle">Page Not Found</div>
    <div class="notFound-p">Unfortunately, we could not find the page. Please try again later</div>
    <a href="/" class="notFound-btn">Back to Home</a>
  </div>
</template>

<script>
  export default {
    name: "NotFoundComponent"
  }
</script>

<style scoped lang="sass">
.notFound-wrapper
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
.notFound-title
  display: flex
  align-items: center
  gap: 10px
  font-size: 200px
.notFound-subtitle
  font-size: 32px
  margin-top: 20px
.notFound-p
  font-size: 16px
  margin: 20px 0
.notFound-btn
  border-radius: 20px
  border: 1px solid rgb(0, 0, 0)
  padding: 15px 20px
  color: inherit
  margin-top: 20px
  transition: .3s ease-in-out
  text-decoration: none
  &:hover
    background: #000
    color: #fff
@media screen and (max-width: 660px)
  .notFound-title
    font-size: 100px
    img
      max-width: 100px
      width: 100%
  .notFound-subtitle
    font-size: 22px
  .notFound-p
    font-size: 14px
    text-align: center
</style>