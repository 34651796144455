<template>
  <CharlaFormPopup></CharlaFormPopup>
  <div class="subPage-background">
    <div class="subPage-wrapper">
      <subPageHeader></subPageHeader>
      <div class="page-title">
        <h1 :class="{ruh1: Tr.currentLocale === 'ru'}">{{ $t("termsOfUse.mainTitle") }}</h1>
      </div>
      <div class="working__wrapper">
        <div class="working__container">
          <div class="working__bloc">
            <p class="working__text working__text__dash">{{ $t("termsOfUse.start.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.start.text2") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.start.text3") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.start.text4") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title1") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block1.text1") }}</p>
            <br>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block1.text2") }}</p>
            <br>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title1_1") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block2.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block2.text2") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block2.text3") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block2.text4") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block2.text5") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title1_2") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block3.text1") }}</p>
            <br>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block3.text2") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title2") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block4.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block4.text2") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block4.text3") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text4") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text5") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text6") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text7") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text8") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text9") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block4.text10") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title3") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text2") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text3") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text4") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block5.text5") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title4") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block6.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block6.text2") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text3") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text4") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text5") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text6") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block6.text7") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block6.text8") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title5") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text2") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text3") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text4") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block7.text5") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title6") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block8.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block8.text2") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title7") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block9.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block9.text2") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block9.text3") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title8") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text2") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text3") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text4") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text5") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text6") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text7") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text8") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block10.text9") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block10.text10") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block10.text11") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block10.text12") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block10.text13") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title9") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text1") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text2") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text3") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text4") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text5") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text6") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text7") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text8") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text9") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text10") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text11") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text12") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text13") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text14") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text15") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text16") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text17") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text18") }}</p>
            <p class="working__text working__text__pad" v-if="Tr.currentLocale==='en'">{{ $t("termsOfUse.block11.text19") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text20") }}</p>
            <p class="working__text working__text__pad" v-if="Tr.currentLocale==='en'">{{ $t("termsOfUse.block11.text21") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block11.text22") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block11.text23") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title10") }}</div>
            <p class="working__text">{{ $t("termsOfUse.block12.text1") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title11") }}</div>
            <p class="working__text">{{ $t("termsOfUse.block13.text1") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title12") }}</div>
            <p class="working__text">{{ $t("termsOfUse.block14.text1") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__tit">{{ $t("termsOfUse.title13") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block15.text1") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block15.text2") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title14") }}</div>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block16.text1") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block16.text2") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block16.text3") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block16.text4") }}</p>
            <p class="working__text working__text__pad" v-if="Tr.currentLocale==='en'">{{ $t("termsOfUse.block16.text5") }}</p>
            <p class="working__text working__text__pad">{{ $t("termsOfUse.block16.text6") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block16.text7") }}</p>
            <p class="working__text working__text__dash">{{ $t("termsOfUse.block16.text8") }}</p>
          </div>
          <div class="working__block">
            <div class="working__block__title">{{ $t("termsOfUse.title15") }}</div>
            <p class="working__text" v-html='$t("termsOfUse.block17.text1")'></p>
          </div>
        </div>
      </div>
      <subPageFooter></subPageFooter>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import subPageFooter from "@/components/subPages/subPageFooter.vue";
import subPageHeader from "@/components/subPages/subPageHeader.vue";
import CharlaFormPopup from "@/components/CharlaFormPopup.vue";

export default {
  name: "publicOffer",
  components: {CharlaFormPopup, subPageHeader, subPageFooter},
  computed: {
    Tr(){
      return Tr
    }
  },

}
</script>

<style scoped lang="sass">
.subPage-background
  background: #F6F5F8
  height: 100%
.subPage-wrapper
  max-width: 1112px
  margin: auto
  padding: 64px 0

.faq-wrapper
  margin: 64px auto

.page-title
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 64px

h1
  font-size: 56px
  font-weight: 700
  margin: 0

  span
    color: #98DA2C

.title-btn
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  max-width: 240px
  width: 100%
  height: 48px
  border-radius: 24px
  background: #000
  color: #fff
  transition: .3s ease
  cursor: pointer
  border: 1px solid #000

  &:hover
    background: #fff
    color: #000

    svg path
      transition: .3s ease
      fill: #000

p
  font-size: 16px
  line-height: 164%

  &:last-child
    margin-bottom: 0

ul
  padding-left: 30px

  li
    font-size: 16px
    margin-bottom: 12px
    line-height: 164%
    list-style-type: disc

h2
  font-size: 22px
  margin: 20px 0 10px 0

h3
  font-size: 24px
  font-weight: 400
  margin: 0

@media screen and (max-width: 1200px)
  .subPage-wrapper
    margin: 0 40px

@media screen and (max-width: 970px)
  .reviews-grid
    grid-template-columns: 1fr 1fr
  h1
    font-size: 32px

@media screen and (max-width: 660px)
  .page-title
    display: flex
    flex-direction: column
    gap: 22px
  .subPage-wrapper
    margin: 0 20px
    padding: 24px 0
  h1
    font-size: 32px
    font-weight: 400
  h2
    font-size: 24px
  h3
    font-size: 18px

  p
    font-size: 13px
    line-height: 154%
  ul li
    font-size: 13px
    line-height: 154%
  .title-btn
    font-size: 14px
    max-width: 200px


</style>

<style scoped lang="scss">
.main__grid {
  display: grid;
  grid-template-columns: minmax(auto, 300px) 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 20px;
}

.main__text {
  padding: 10px;
  height: 100%;
}
.main__grid > p, .main__grid > div {
  border: 1px solid #98da2c;
}
.main__grid__title {
  font-size: 20px;
  font-weight: 500;
  padding: 10px;
  justify-self: center;
  width: 100%;
  text-align: center;
}
.main__list_hollow {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 10px;
}
.main__list_hollow > li {
  position: relative;
  padding: 0 0 0 80px;
}
.main__list_hollow > li::before {
  content: "";
  border: #98da2c 1px solid;
  position: absolute;
  border-radius: 50%;
  left: 60px;
  top: 7px;
  width: 6px;
  height: 6px;
}
.working {

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;

    &__pad{
      padding: 0 0 16px 30px;
    }

    &__dash{
      position: relative;
      padding: 0 0 16px 30px;

      &::before{
        content: url("@/assets/img/termsDash.svg");
        position: absolute;
        left: 0;
        top: -5px;
      }
    }
  }

  &__block {
    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      padding: 0 0 32px 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    & > li.working__list__marked {
      grid-template: 1fr / 72px 1fr;

      & > p {
        position: relative;

        & > svg {
          position: absolute;
          left: 50px;
          top: 9px;
        }
      }
    }
  }
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }
  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }
  to {
    transform: translate(50%, 120vh);
  }
}
.working__block__title{
  padding: 0;
  margin: 32px 0;
}
@media screen and (max-width: 660px) {
  .main__grid__title{
    font-size: 13px;
    padding: 5px;
    border: none!important;
  }
  .main__grid{
    grid-template-columns: minmax(auto, 200px) 1fr;
    border: 1px solid #98da2c;
    overflow: hidden;
  }
  .first{
    margin: -5px 0 0 0!important;
    border-top: 1px solid #98da2c;
  }
  .main__grid > p, .main__grid > div {
    border: none;
  }
  .main__grid > p {
    border-top: 1px solid #98da2c;
  }

  .working__title {
    font-size: 28px;
    margin: 10px 0 0 0
  }

  .working__list {
    gap: 8px;
    padding-left: 20px;
  }
  .working__text {
    margin: 0;
    font-size: 13px;
  }
  .working__block__title {
    font-size: 18px;
    margin: 16px 0;
    text-align: center;
    padding: 0;
  }
  .working__list > li.working__list__marked > p {
    margin: 0;
  }
  .main__text {
    font-size: 13px;
    padding: 5px;
    margin: 0;
  }
}
</style>