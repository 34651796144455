<template>
  <div class="accordion-item-wrapper" :class="{ openItem: isOpen }">
    <div class="accordion-header" @click="toggleAccordion">
      <slot name="header"></slot>
      <div class="cross">
        <div class="hide"><span></span><span></span></div>
        <div class="open"></div>
      </div>
    </div>
    <transition name="slide">
      <div class="accordion-content" v-if="isOpen">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'accordionItem',
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style scoped lang="scss">
.accordion-item-wrapper {
  padding: 32px 48px;
  border-left: 8px solid #B561F6;
  border-radius: 32px;
  transition: .5s ease;
  margin-bottom: 16px;
  background: #fff;
}


.cross{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  transition: .3s ease;
  flex: none;
}
.accordion-content{
  padding-top: 24px;
  font-size: 14px;
  line-height: 157%;
  font-weight: 400;
}
.accordion-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  gap: 20px;

}
.openItem{
  border-color: #000;
}

.open{
  width: 100%;
  height: 2px;
  background: #B561F6;
  display: none;

}
.openItem .open{
  display: block;
}

.hide{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s ease;
}
.hide span{
  display: block;
  width: 100%;
  height: 2px;
  background: #B561F6;
  transition: .3s ease;
}
.hide span:nth-child(1){
  position: absolute;
  width: 2px;
  height: 100%;
  background: #B561F6;
}
.openItem .hide{
  display: none;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
  padding: 0;
  overflow: hidden;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 200px;
  opacity: 1;

}
@media screen and (max-width: 660px) {
  .accordion-header{
    font-size: 15px;

  }
  .accordion-content{
    font-size: 13px;
  }

}
</style>
