<template>
  <div class="faq__popup" v-show="getFaqState">
    <div class="faq__background" @click="closeFaq()"></div>
    <div class="faq__wrapper">
      <div class="faq__close" @click="closeFaq()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
              fill="black"/>
        </svg>
      </div>
      <h2 class="faq__title">
        {{$t(`${getFaqContent}.header`)}}
      </h2>
      <p class="faq__text">
        {{$t(`${getFaqContent}.text`)}}
      </p>
    </div>
  </div>

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Tr from "@/i18n/translation";
export default {
  name: "CharlaFaqPopup",
  methods:{
    ...mapActions(['setFaqState', 'setFaqContent']),
    closeFaq(){
      const faqBackground = document.querySelector('.faq__background');
      const faqContent = document.querySelector('.faq__wrapper');

      faqBackground.classList.add('faq__background_disable');
      faqContent.classList.add('faq__wrapper_disable');

      setTimeout(() => {
        faqBackground.classList.remove('faq__background_disable');
        faqContent.classList.remove('faq__wrapper_disable');
        this.setFaqState(false)
      }, 500)
    }
  },
  computed:{
    ...mapGetters(['getFaqContent', 'getFaqState'])
  },
  setup(){
    return{Tr}
  },
}

</script>



<style scoped lang="scss">
  .faq{
    &__background{
      width: 100vw;
      height: 100vh;
      background-color: rgba(0,0,0,0.6);
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9999;
      transition: 300ms ease all;
      animation: 300ms ease __fadeIn forwards;

      &_disable{
        animation: 300ms ease __fadeOut forwards;
      }
    }

    &__close{
      position: absolute;
      right: -24px;
      top: 24px;
      transform: translate(100%, 0);
      cursor: pointer;
      background-color: white;
      padding: 14px;
      border-radius: 50%;
    }

    &__wrapper{
      max-width: 840px;
      width: 100%;
      background-color: white;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      z-index: 10000;
      padding: 64px;
      border-radius: 44px;
      gap: 48px;
      transition: 500ms ease all;
      animation: __slideUp 500ms ease forwards;

      &_disable{
        animation: __slideDown 500ms ease forwards;
      }
    }

    &__title{
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
    }

    &__text{
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
  }

  @keyframes __fadeIn  {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes __fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes __slideUp {
    from {
      top: 200vh;
    }
    to{
      top: 50%;
    }
  }

  @keyframes __slideDown {
    from{
      top: 50%;
    }
    to{
      top: 200vh;
    }
  }

  @media  screen and (max-width: 1000px){
    .faq__wrapper {
      max-width: 700px;
    }
  }

  @media  screen and (max-width: 850px){
    .faq__wrapper {
      max-width: 600px;
    }
  }

  @media  screen and (max-width: 750px){
    .faq__wrapper {
      max-width: 500px;
    }
  }

  @media  screen and (max-width: 658px){
    .faq__wrapper {
      padding: 42px;
      max-width: 400px;
      border-radius: 26px;
    }
    .faq__title{
      margin: 0;
    }

    .faq__text{
      margin: 0;
    }

    .faq__close{
      display: grid;
      width: 36px;
      height: 36px;
      background-color: black;
      place-items: center;
      top: 10px;
      right: 46px;
      padding: 0;

      & > svg{
        width: 15px;
        height: 15px;

        & > path{
          fill: white;
        }
      }
    }
  }

  @media  screen and (max-width: 600px){
    .faq__wrapper {
      gap: 12px;
    }

    .faq__title{
      font-size: 22px;
      line-height: 100%;
    }
  }

  @media  screen and (max-width: 500px){
    .faq__wrapper {
      max-width: 300px;
    }
  }
  @media  screen and (max-width: 400px){
    .faq__wrapper {
      max-width: 270px;
    }
  }


</style>