<template>
  <CharlaFormPopup></CharlaFormPopup>
  <div class="subPage-background">
    <div class="subPage-wrapper">
      <subPageHeader></subPageHeader>
      <div class="faq-wrapper">
        <div class="page-title">
          <h1>{{ $t("faqPage.title") }}</h1>
          <div class="title-btn" @click="setFormState(true)">
            <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0H8.5C4.08 0 0.5 3.58 0.5 8C0.5 12.42 4.08 16 8.5 16H14.5C18.92 16 22.5 12.42 22.5 8C22.5 3.58 18.92 0 14.5 0ZM14.5 14H8.5C5.19 14 2.5 11.31 2.5 8C2.5 4.69 5.19 2 8.5 2H14.5C17.81 2 20.5 4.69 20.5 8C20.5 11.31 17.81 14 14.5 14ZM7.5 5H15.5V7H7.5V5ZM7.5 9H11.5V11H7.5V9Z" fill="white" />
            </svg>
            {{ $t("faqPage.btn") }}</div>
        </div>

        <accordionItem>
          <template #header>
            {{ $t("faqPage.faq.qa1.header") }}
          </template>
          {{ $t("faqPage.faq.qa1.text") }}
        </accordionItem>

        <accordionItem>
          <template #header>
            {{ $t("faqPage.faq.qa2.header") }}
          </template>
          {{ $t("faqPage.faq.qa2.text") }}
        </accordionItem>

        <accordionItem>
          <template #header>
            {{ $t("faqPage.faq.qa3.header") }}
          </template>
          {{ $t("faqPage.faq.qa3.text") }}
        </accordionItem>

        <accordionItem>
          <template #header>
            {{ $t("faqPage.faq.qa4.header") }}
          </template>
          {{ $t("faqPage.faq.qa4.text") }}
        </accordionItem>

        <accordionItem>
          <template #header>
            {{ $t("faqPage.faq.qa5.header") }}
          </template>
          {{ $t("faqPage.faq.qa5.text") }}
        </accordionItem>

        <accordionItem>
          <template #header>
            {{ $t("faqPage.faq.qa6.header") }}
          </template>
          {{ $t("faqPage.faq.qa6.text") }}
        </accordionItem>

      </div>
      <subPageFooter></subPageFooter>
    </div>
  </div>
</template>

<script>
import subPageHeader from "@/components/subPages/subPageHeader.vue";
import subPageFooter from "@/components/subPages/subPageFooter.vue";
import accordionItem from '@/components/subPages/accordionItem.vue';

import {mapActions} from "vuex";
import CharlaFormPopup from "@/components/CharlaFormPopup.vue";
export default {
  name: "faqPage",
  components: {subPageHeader,accordionItem, subPageFooter, CharlaFormPopup},
  mounted() {
    window.addEventListener('popstate', this.handleBackButton);
  },

  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  },

  methods: {
    ...mapActions(['setFormState']),

    handleBackButton(event) {
      event.preventDefault();
      window.location.href = '/';
    }
  }
}
</script>

<style lang="sass" scoped>
.subPage-background
  background: #F6F5F8
.subPage-wrapper
  max-width: 1112px
  margin: auto
  padding: 64px 0

.faq-wrapper
  margin: 64px auto

.page-title
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 64px
h1
  font-size: 56px
  font-weight: 700
.title-btn
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  max-width: 240px
  width: 100%
  height: 48px
  border-radius: 24px
  background: #000
  color: #fff
  border: 1px solid #000
  transition: .3s ease
  cursor: pointer
  &:hover
    background: #fff
    color: #000
    svg path
      transition: .3s ease
      fill: #000

p
  font-size: 16px
  margin-bottom: 12px
  line-height: 164%

  &:last-child
    margin-bottom: 0
ul
  padding-left: 30px
  li
    font-size: 16px
    margin-bottom: 12px
    line-height: 164%
    list-style-type: disc

h2
  font-size: 32px
  margin: 40px 0 30px 0
h3
  font-size: 24px
  font-weight: 400
  margin: 0
@media screen and (max-width: 1200px)
    .subPage-wrapper
      margin: 0 40px
@media screen and (max-width: 660px)
  .subPage-wrapper
    margin: 0 20px
    padding: 24px 0
  .faq-wrapper
    width: 100%
    margin: 24px 0
  h1
    font-size: 34px
    font-weight: 400
  h2
    font-size: 24px
  h3
    font-size: 18px

  p
    font-size: 13px
    line-height: 154%
  ul li
    font-size: 13px
    line-height: 154%
  .title-btn
    font-size: 14px
    max-width: 200px

</style>