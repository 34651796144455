<template>
  <div class="block" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <div class="block__title">
        <span>
          <span class="block__title__icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 42 24" style="enable-background:new 0 0 42 24;" xml:space="preserve">
              <path class="st0" d="M32.8,1.9C32.9,2.2,33,2.6,33,3s-0.1,0.8-0.2,1.1l-0.9-0.4C31.9,3.5,32,3.3,32,3s-0.1-0.5-0.2-0.8L32.8,1.9z
                 M30,5c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9C29.2,5.9,29.6,6,30,6s0.8-0.1,1.1-0.2l-0.4-0.9C30.5,4.9,30.3,5,30,5z M27.2,1.9
                C27.1,2.2,27,2.6,27,3s0.1,0.8,0.2,1.1l0.9-0.4C28.1,3.5,28,3.3,28,3s0.1-0.5,0.2-0.8L27.2,1.9z M28.9,0.2l0.4,0.9
                C29.5,1.1,29.7,1,30,1s0.5,0.1,0.8,0.2l0.4-0.9C30.8,0.1,30.4,0,30,0S29.2,0.1,28.9,0.2z M22.8,11.2c0.1,0.2,0.2,0.5,0.2,0.8
                s-0.1,0.5-0.2,0.8l0.9,0.4c0.1-0.4,0.2-0.7,0.2-1.1s-0.1-0.8-0.2-1.1L22.8,11.2z M21,14c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9
                c0.4,0.1,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.2l-0.4-0.9C21.5,13.9,21.3,14,21,14z M18.2,10.9C18.1,11.2,18,11.6,18,12s0.1,0.8,0.2,1.1
                l0.9-0.4C19.1,12.5,19,12.3,19,12s0.1-0.5,0.2-0.8L18.2,10.9z M19.9,9.2l0.4,0.9c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.8,0.2l0.4-0.9
                C21.8,9.1,21.4,9,21,9S20.2,9.1,19.9,9.2z M4.8,20.2C4.9,20.5,5,20.7,5,21s-0.1,0.5-0.2,0.8l0.9,0.4C5.9,21.8,6,21.4,6,21
                s-0.1-0.8-0.2-1.1L4.8,20.2z M3,23c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9C2.2,23.9,2.6,24,3,24s0.8-0.1,1.1-0.2l-0.4-0.9
                C3.5,22.9,3.3,23,3,23z M0.2,19.9C0.1,20.2,0,20.6,0,21s0.1,0.8,0.2,1.1l0.9-0.4C1.1,21.5,1,21.3,1,21s0.1-0.5,0.2-0.8L0.2,19.9z
                 M1.9,18.2l0.4,0.9C2.5,19.1,2.7,19,3,19s0.5,0.1,0.8,0.2l0.4-0.9C3.8,18.1,3.4,18,3,18S2.2,18.1,1.9,18.2z M13.8,20.2
                c0.1,0.2,0.2,0.5,0.2,0.8s-0.1,0.5-0.2,0.8l0.9,0.4c0.1-0.4,0.2-0.7,0.2-1.1s-0.1-0.8-0.2-1.1L13.8,20.2z M12,23
                c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9c0.4,0.1,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.2l-0.4-0.9C12.5,22.9,12.3,23,12,23z M9.2,19.9
                C9.1,20.2,9,20.6,9,21s0.1,0.8,0.2,1.1l0.9-0.4C10.1,21.5,10,21.3,10,21s0.1-0.5,0.2-0.8L9.2,19.9z M10.9,18.2l0.4,0.9
                c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.8,0.2l0.4-0.9C12.8,18.1,12.4,18,12,18S11.2,18.1,10.9,18.2z M22.8,20.2
                c0.1,0.2,0.2,0.5,0.2,0.8s-0.1,0.5-0.2,0.8l0.9,0.4c0.1-0.4,0.2-0.7,0.2-1.1s-0.1-0.8-0.2-1.1L22.8,20.2z M21,23
                c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9c0.4,0.1,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.2l-0.4-0.9C21.5,22.9,21.3,23,21,23z M18.2,19.9
                C18.1,20.2,18,20.6,18,21s0.1,0.8,0.2,1.1l0.9-0.4C19.1,21.5,19,21.3,19,21s0.1-0.5,0.2-0.8L18.2,19.9z M19.9,18.2l0.4,0.9
                c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.8,0.2l0.4-0.9C21.8,18.1,21.4,18,21,18S20.2,18.1,19.9,18.2z M31.8,11.2
                c0.1,0.2,0.2,0.5,0.2,0.8s-0.1,0.5-0.2,0.8l0.9,0.4c0.1-0.4,0.2-0.7,0.2-1.1s-0.1-0.8-0.2-1.1L31.8,11.2z M30,14
                c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9c0.4,0.1,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.2l-0.4-0.9C30.5,13.9,30.3,14,30,14z M27.2,10.9
                C27.1,11.2,27,11.6,27,12s0.1,0.8,0.2,1.1l0.9-0.4C28.1,12.5,28,12.3,28,12s0.1-0.5,0.2-0.8L27.2,10.9z M28.9,9.2l0.4,0.9
                c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.8,0.2l0.4-0.9C30.8,9.1,30.4,9,30,9S29.2,9.1,28.9,9.2z M31.8,20.2c0.1,0.2,0.2,0.5,0.2,0.8
                s-0.1,0.5-0.2,0.8l0.9,0.4c0.1-0.4,0.2-0.7,0.2-1.1s-0.1-0.8-0.2-1.1L31.8,20.2z M30,23c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9
                c0.4,0.1,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.2l-0.4-0.9C30.5,22.9,30.3,23,30,23z M27.2,19.9C27.1,20.2,27,20.6,27,21s0.1,0.8,0.2,1.1
                l0.9-0.4C28.1,21.5,28,21.3,28,21s0.1-0.5,0.2-0.8L27.2,19.9z M28.9,18.2l0.4,0.9c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.8,0.2l0.4-0.9
                C30.8,18.1,30.4,18,30,18S29.2,18.1,28.9,18.2z M41.8,13.1c0.1-0.4,0.2-0.7,0.2-1.1s-0.1-0.8-0.2-1.1l-0.9,0.4
                c0.1,0.2,0.2,0.5,0.2,0.8s-0.1,0.5-0.2,0.8L41.8,13.1z M39,14c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9c0.4,0.1,0.7,0.2,1.1,0.2
                s0.8-0.1,1.1-0.2l-0.4-0.9C39.5,13.9,39.3,14,39,14z M36.2,10.9C36.1,11.2,36,11.6,36,12s0.1,0.8,0.2,1.1l0.9-0.4
                C37.1,12.5,37,12.3,37,12s0.1-0.5,0.2-0.8L36.2,10.9z M37.9,9.2l0.4,0.9c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.8,0.2l0.4-0.9
                C39.8,9.1,39.4,9,39,9S38.2,9.1,37.9,9.2z M40.8,20.2c0.1,0.2,0.2,0.5,0.2,0.8s-0.1,0.5-0.2,0.8l0.9,0.4c0.1-0.4,0.2-0.7,0.2-1.1
                s-0.1-0.8-0.2-1.1L40.8,20.2z M39,23c-0.3,0-0.5-0.1-0.8-0.2l-0.4,0.9c0.4,0.1,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.2l-0.4-0.9
                C39.5,22.9,39.3,23,39,23z M36.2,19.9C36.1,20.2,36,20.6,36,21s0.1,0.8,0.2,1.1l0.9-0.4C37.1,21.5,37,21.3,37,21s0.1-0.5,0.2-0.8
                L36.2,19.9z M37.9,18.2l0.4,0.9c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.8,0.2l0.4-0.9C39.8,18.1,39.4,18,39,18S38.2,18.1,37.9,18.2z" fill="#FFF"/>
            </svg>
          </span>
          {{ $t("CharlaStatistics.title_part1") }}
        </span>
        <span v-html='$t("CharlaStatistics.title_part2")'></span>
      </div>
      <div class="statistics__slogan">
        {{ $t("CharlaStatistics.slogan") }}
      </div>
      <div class="statistics__link">
        {{ $t("CharlaStatistics.info") }} <span v-if="Tr.currentLocale==='en'">⟶</span>
      </div>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="statistic__carousel" data-carousel="true" data-hidden-index="0">
        <div class="statistic__carousel__wrapper">
          <div class="statistic__carousel__item">
            <div class="statistic__carousel__item__container statistic__carousel__item_red">
              <div class="statistic__carousel__item__title">
                {{ $t("CharlaStatistics.slider.carouselItem1.title") }}
              </div>
              <p class="statistic__carousel__item__numbers">
                <span>x46</span>
                {{ $t("CharlaStatistics.slider.carouselItem1.numbers") }}
              </p>
              <div class="statistic__carousel__item__comparison">
                <div class="statistic__carousel__item__comparison__left">
                  <span>Charla:</span><br><div v-html='$t("CharlaStatistics.slider.carouselItem1.vs_charla")'></div>
                </div>
                <p>{{ $t("CharlaStatistics.slider.carouselItem1.vs") }}</p>
                <div class="statistic__carousel__item__comparison__right">
                  <span>{{ $t("CharlaStatistics.slider.carouselItem1.sub_title") }}</span><br>
                  <div v-html='$t("CharlaStatistics.slider.carouselItem1.vs_human")'></div>
                </div>
              </div>
            </div>
          </div>
          <div class="statistic__carousel__item ">
            <div class="statistic__carousel__item__container statistic__carousel__item_purple">
              <div class="statistic__carousel__item__title">
                {{ $t("CharlaStatistics.slider.carouselItem2.title") }}
              </div>
              <p class="statistic__carousel__item__numbers">
                <span>100%</span>
                {{ $t("CharlaStatistics.slider.carouselItem2.numbers") }}
              </p>
              <div class="statistic__carousel__item__comparison">
                {{ $t("CharlaStatistics.slider.carouselItem2.text_part1") }} <span>{{ $t("CharlaStatistics.slider.carouselItem2.text_part2")}}&nbsp;
              </span>{{ $t("CharlaStatistics.slider.carouselItem2.text_part3") }}
              </div>
            </div>
          </div>
          <div class="statistic__carousel__item ">
            <div class="statistic__carousel__item__container statistic__carousel__item_green">
              <div class="statistic__carousel__item__title">
                {{ $t("CharlaStatistics.slider.carouselItem3.title") }}
              </div>
              <p class="statistic__carousel__item__numbers">
                <span>60%</span>
                {{ $t("CharlaStatistics.slider.carouselItem3.numbers") }}
              </p>
              <div class="statistic__carousel__item__comparison">
                {{ $t("CharlaStatistics.slider.carouselItem3.text_part1") }} <span>
                {{ $t("CharlaStatistics.slider.carouselItem3.text_part2") }}
              </span> {{ $t("CharlaStatistics.slider.carouselItem3.text_part3") }} <span>{{ $t("CharlaStatistics.slider.carouselItem3.text_part4") }}
              </span>&nbsp;{{ $t("CharlaStatistics.slider.carouselItem3.text_part5") }} &nbsp;<span>
                {{ $t("CharlaStatistics.slider.carouselItem3.text_part6") }}
              </span>
                <br v-if="Tr.currentLocale==='es'">
                <br v-if="Tr.currentLocale==='es'">
              </div>
            </div>
          </div>
          <div class="statistic__carousel__item ">
            <div class="statistic__carousel__item__container statistic__carousel__item_yellow">
              <div class="statistic__carousel__item__title">
                {{ $t("CharlaStatistics.slider.carouselItem4.title") }}
              </div>
              <div class="statistic__carousel__item__numbers">
                <div>
                  <span>100+</span>
                  {{ $t("CharlaStatistics.slider.carouselItem4.numbers") }}
                </div>
                <div>
                  <span>1M+</span>
                  {{ $t("CharlaStatistics.slider.carouselItem4.text_part1") }}
                </div>
              </div>
              <div class="statistic__carousel__item__comparison">
                {{ $t("CharlaStatistics.slider.carouselItem4.text_part2") }}
                <br>
                <br>
                <br v-if="Tr.currentLocale !== 'en'">
                <br v-if="Tr.currentLocale !== 'en'">
              </div>
            </div>
          </div>
        </div>
        <div class="carousel__button__wrapper">
          <button :class="this.hiddenIndex > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'" class="carousel__button carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
          <button :class="this.hideArrow ? 'carousel__button_disabled' : 'carousel__button_active'" class="carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Tr from "@/i18n/translation";

export default {
  name: "CharlaStatistics",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false
    }
  },
  computed:{
    Tr() {
      return Tr
    },
  },
  methods: {
    scrollCarousel(direction='left'){
      const carouselItems = [...document.querySelectorAll('.statistic__carousel__item')]
      const carouselItemsContainer = [...document.querySelectorAll('.statistic__carousel__item__container')]
      const carouselButtons = [...document.querySelectorAll('.carousel__button')]

      carouselButtons.forEach(e => {
        e.style.pointerEvents = 'none'
        setTimeout(() => {
          e.style.pointerEvents = 'all'
        }, 500)
      })

      if (direction === 'right'){
        carouselItemsContainer.forEach((e,i) => {
          if (i === this.hiddenIndex){
            e.classList.add('disappear')
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')){
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex + 1)}% - ${16 * (this.hiddenIndex + 1)}px), 0)`
          }
        })

        this.hiddenIndex += 1
      } else {
        this.hiddenIndex -= 1

        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex){
            e.classList.add('appear')
            setTimeout(() => {
              e.classList.remove('disappear')
              e.classList.remove('appear')
            }, 500)
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex)}% - ${16 * (this.hiddenIndex)}px), 0)`
          }
        })

      }

      const carousel = document.querySelector('.statistic__carousel');
      carousel.dataset.hiddenIndex = this.hiddenIndex
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
          this.hideArrow = true
        } else {
          splideContainer.dataset.stopScroll = 'true'
          this.hideArrow = false
        }
      })
    }, {
      threshold: 1
    });

    let money__statistic__carouselItem = [...document.querySelectorAll('.statistic__carousel__item')];
    money__statistic__carouselItem = money__statistic__carouselItem[money__statistic__carouselItem.length - 1]
    observer.observe(money__statistic__carouselItem)

    const carousel = document.querySelector('.statistic__carousel')
    this.hiddenIndex = +carousel.dataset.hiddenIndex


    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.hiddenIndex = +carousel.dataset.hiddenIndex
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>


<style scoped lang="scss">
  .block__title__icon{
    padding: 8px;
    background: var(--red);

    & > svg{
      width: 100%;
      height: 100%;
    }
  }

  .statistics__slogan{
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
    max-width: 368px;
  }

  .statistics__link{
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    color: #000;
    display: flex;
    align-items: center;
    gap: 10px;

    & > span{
      font-weight: 500;
      font-size: 24px;
      color: var(--red);
    }
  }

  .block__right{
    position: relative;
  }

  .statistic__carousel{
    position: absolute;
    top: 50%;
    left: -80px;
    transform: translate(0, -50%);

    &__wrapper{
      display: flex;
      gap: 16px;
      position: relative;
      overflow: hidden;
    }

    &__item{
      width: 360px;
      height: 360px;
      display: grid;
      place-items: center;


      &__container{
        padding: 32px;
        border-radius: 32px;
        display: grid;
        row-gap: 16px;
        grid-template: auto 1fr auto / 1fr;
        width: 100%;
        height: 100%;
      }


      &__title{
        font-weight: 600;
        font-size: 22px;
        line-height: 127%;
        color: #000;
      }

      &__numbers{
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 8px;

        & > span{
          font-weight: 700;
          font-size: 56px;
          line-height: 100%;

        }
      }

      &_red{
        background-color: var(--red);
      }

      &_purple{
        background-color: var(--purple);
      }

      &_green{
        background-color: var(--green);
      }

      &_yellow{
        background-color: var(--purple);
      }
    }

  }

  .statistic__carousel__item__comparison{
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;


    &__right{
      text-align: right;
    }

    & span{
      color: white;
    }

    & p{
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #fff;
    }
  }
  .statistic__carousel__item_red  .statistic__carousel__item__comparison {
    display: grid;
    grid-template: 1fr / auto 1fr auto;
  }

  .statistic__carousel__item_green .statistic__carousel__item__comparison{
    max-width: 240px;
  }

  .statistic__carousel__item_yellow .statistic__carousel__item__numbers{
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    gap: 12px;
    & div{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #fff;
      gap: 8px;

      & > span{
        font-weight: 700;
        font-size: 56px;
        line-height: 100%;
        text-align: center;
        color: #fff;
      }
    }
  }

  .statistic__carousel >  div:nth-child(0){
    background-color: var(--red);
  }

  .carousel__button{
    background-color: var(--red);

    &__back{
      background-color: black;
    }
  }
  @media screen and (max-width: 1300px) {
    .statistic__carousel{
      left: -10px;
    }
  }

</style>